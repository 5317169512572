//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// services Screen
//////////////////////////////////////////////////////////////////////////////////////////

.services-cover-ar{
  position: relative;
  height: 580px;
  background: #f1f5f7 url("../img/cover-services-ar.svg")  center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  .titleWizSlogan{
    z-index: 3;
    padding-top: 215px;
    .AnimateSmall{
      letter-spacing: 30px;
    }
  }
}

.services-ar{
  position: relative;
  padding-bottom: 40px;
  .services_block_text{
    margin-top: 50px;
    color: #3a3a3a;
    h2{
      @include selectColors(#a9b5b9, $whiteo);
    }
    p{
      @include selectColors(#a9b5b9, $whiteo);
    }
  }
  .imgInCover{
    @include userSelect(none);

    img{
      width: 100%;
      @media only screen and (min-width : 100px) {
        position: static;
        width: 100%;
        margin-top: -50px;

      }
      @media only screen and (min-width : 320px) {
        position: static;
        width: 100%;
        margin-top: -50px;

      }
      @media only screen and (min-width : 480px) {
        position: static;
        width: 100%;
        margin-top: -50px;

      }
      @media only screen and (min-width : 768px) {
        position: static;
        width: 100%;
        margin-top: 50px;

      }
      @media only screen and (min-width : 992px) {
        position: absolute;
        top: -115px;
      }
      @media only screen and (min-width : 1200px) {
        position: absolute;
        top: -247px;
        width: 725px;
      }
    }
  }

}
