
/*about Page*/
.contactPage-cover{
  height: 580px;
  background:  url("../img/cover-contact.svg")  center top no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    height: 580px;
  }
  @media (max-width: 480px) {
    height: 500px;
  }
  .contactinfoContainer{
    //padding-top: 185px;
    .contact-info{
      background: $whiteo url("../img/bg-slices.svg")  center center no-repeat;
      background-size: cover;
      margin-top: 60px;
      @media (max-width: 992px) {
        margin-top: -15px;
      }
      @include vendor-prefixes(border-radius, 15px);
      //margin-top: 260px;
      padding: 30px;
      h3{

      }
      p{
        span{
          color: $dark_cyano;
          margin-left: 2px;
        }
        &:nth-child(2){
          display: block;
        }
        &:nth-child(3){
          display: inline-block;
        }
        &:nth-child(4){
          display: inline-block;
        }
        &:nth-child(5){
          display: inline-block;

        }
      }
    }

  }
  .earthDivContainer{
    padding-top: 195px;
    @media (max-width: 992px) {
      padding-top: 140px;
    }
    @media (max-width: 768px) {
      padding-top: 100px;
    }
    @media (max-width: 480px) {
      padding-top: 190px;
    }
    .earthDiv{
      position: relative;
      background:  url("../img/earth-lowpoly.png")  center center no-repeat;
      background-size: contain;
      width: 370px;
      height: 370px;
      display: block;
      margin: auto;
      @media (max-width: 992px) {
        width: 275px;
        height: 275px;
      }
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
      img.googleMap{
        position: absolute;
        top: 150px;
        right: 98px;
        width: 175px;
        z-index: 3;
        @media (max-width: 992px) {
          top: 112px;
          right: 79px;
          width: 120px;
        }
      }
      img.rockets{
        position: absolute;
        width: 50px;
        top: -51px;
        left: 12px;
        @include vendor-prefixes(transform, rotate(-44deg));
        z-index: 4;
      }
      img.eq{
        position: absolute;
        width: 335px;
        bottom: -44px;
        right: 0;
        left: 0;
        z-index: -1;
        @media (max-width: 992px) {
          width: 230px;
        }
      }
    }
  }
}

/*support Center Div*/
.supportCenterDiv{
  margin-top: 30px;
  .LoginLogout{
    background: $whiteo url("../img/bg-slices.svg")  center center no-repeat;
    background-size: contain;
    @include vendor-prefixes(border-radius, 15px);
    @include userSelect(none);

    padding: 30px;
    margin-bottom: 50px;
    input{
      background: #f1f5f7;
      @include vendor-prefixes(border-radius, 50px);
      padding: 2px 3px 2px 20px;
      font-size: 14px;
      margin-bottom: 5px;
      margin-top: 5px;
      border: 1px solid transparent;
      @include vendor-prefixes(transition, all 1s ease-in-out);
      max-width: 100%;
      @media (min-width: 1200px) {
        max-width: 350px;
      }
    }
    input:focus:not([readonly]){
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
      border: 1px solid $light_cyano;
    }


    textarea{
      height: 135px;
      resize: none;
      background:#f1f5f7;
      border: 1px solid #f1f5f7;
      padding: 20px;
      font-size: 15px;
      margin: 0;
      max-width: 370px;
      margin-top: 5px;
      @include vendor-prefixes(border-radius, 20px);
      @include vendor-prefixes(transition, all 0.6s ease-in-out);

      &:focus{
        background: #eef2f4;
        padding: 20px;
        font-size: 15px;
        outline: 0;
        box-shadow: 0 0 0 0 rgba(66,133,244,0);
        border: 1px solid #28cae6;
      }

      &::-webkit-scrollbar-track {
        //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        @include vendor-prefixes(border-radius, 1px);
        background-color: #FFFFFF;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        @include vendor-prefixes(border-radius, 10px);
        //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #cdcdcd;
      }
    }



    a{
      font-size: 14px;
      text-align: left;
    }
    button{
      display: block;
      background: #2fc1e4;
      width: 100%;
      max-width: 375px;
      margin: 14px auto;
      border-radius: 100px;
      min-height: 42px;
      outline: 0;
      border:2px solid $whiteo;
      padding: 2px;
      color: $whiteo;
      @include vendor-prefixes(border-radius, 200px);
      @include vendor-prefixes(transition, all 0.6s ease-in-out);
      font-size: 15px;
      text-decoration:none;
      position: relative;

      i{
        font-size: 14px;
        @include vendor-prefixes(transition, all 0.6s ease-in-out);
      }
      &:hover {
        @include vendor-prefixes(transition, all 0.6s ease-in-out);
        background: $dark_cyano;
        color: $whiteo;
        i{
          margin-left: 8px;
        }
      }
    }
    img{
      width: 100%;
    }
  }
}


/*support Center Div*/

.LoginLogout{
  img{
    width: 100%;
  }
}