//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// services Screen
//////////////////////////////////////////////////////////////////////////////////////////

.services-cover-games{
  position: relative;
  height: 580px;
  background: #f1f5f7 url("../img/cover-services-games.svg")  center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  .titleWizSlogan{
    z-index: 3;
    padding-top: 215px;
    .AnimateSmall{
      letter-spacing: 30px;
    }
  }
}

.services-games{
  position: relative;
  padding-bottom: 40px;
  .services_block_text{
    margin-top: 50px;
    color: #3a3a3a;
    h2{
      @include selectColors(#328c2e, $whiteo);
    }
    p{
      @include selectColors(#328c2e, $whiteo);
    }
  }
  .imgInCover{
    @include userSelect(none);
    img{
      width: 100%;
      @media only screen and (min-width : 100px) {
        position: static;
        width: 100%;
        margin-top: -70px;

      }
      @media only screen and (min-width : 320px) {
        position: static;
        width: 100%;
        margin-top: -85px;

      }
      @media only screen and (min-width : 480px) {
        position: static;
        width: 100%;
        margin-top: -50px;

      }
      @media only screen and (min-width : 768px) {
        position: static;
        width: 100%;
        margin-top: 50px;

      }
      @media only screen and (min-width : 992px) {
        position: absolute;
        top: -115px;
      }
      @media only screen and (min-width : 1200px) {
        position: absolute;
        top: -210px;
        width: 540px;

      }
      @media only screen and  (min-width: 1450px) {
        position: absolute;
        top: -315px;
        width: 650px;
      }
    }
  }

}
