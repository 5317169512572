$pentavalue-font-path: "../font/pentavalue" !default;

$icon-icon--website: "\e93c";
$icon--3d: "\e900";
$icon--add: "\e901";
$icon--add-square: "\e902";
$icon--add-thin: "\e903";
$icon--android: "\e904";
$icon--apple: "\e905";
$icon--arow-down: "\e906";
$icon--arow-left: "\e907";
$icon--arow-right: "\e908";
$icon--arow-up: "\e909";
$icon--behance: "\e90a";
$icon--blackberry: "\e90b";
$icon--camera: "\e90c";
$icon--caret-down: "\e90d";
$icon--caret-left: "\e90e";
$icon--caret-right: "\e90f";
$icon--caret-up: "\e910";
$icon--check: "\e911";
$icon--check-circle: "\e912";
$icon--checkhand: "\e913";
$icon--check-thin: "\e914";
$icon--cheveron-down: "\e915";
$icon--cheveron-left: "\e916";
$icon--cheveron-right: "\e917";
$icon--cheveron-top: "\e918";
$icon--classroom: "\e919";
$icon--close: "\e91a";
$icon--close-circle: "\e91b";
$icon--cog: "\e91c";
$icon--conversation: "\e91d";
$icon--erp: "\e91e";
$icon--facebook: "\e91f";
$icon--googleplus: "\e920";
$icon--headphone: "\e921";
$icon--image: "\e922";
$icon--info: "\e923";
$icon--joystick: "\e924";
$icon--link: "\e925";
$icon--linkedin: "\e926";
$icon--location: "\e927";
$icon--message: "\e928";
$icon--message-thin: "\e929";
$icon--money: "\e92a";
$icon--more-horizontal: "\e92b";
$icon--more-vertical: "\e92c";
$icon--nav: "\e92d";
$icon--pentavalue: "\e92e";
$icon--phone: "\e92f";
$icon--play: "\e930";
$icon--reloadmore: "\e931";
$icon--ribbon: "\e932";
$icon--search: "\e933";
$icon--smartphone-l: "\e934";
$icon--smartphone-p: "\e935";
$icon--twitter: "\e936";
$icon--up-down: "\e937";
$icon--users: "\e938";
$icon--viemio: "\e939";
$icon--windows: "\e93a";
$icon--youtube: "\e93b";


@font-face {
  font-family: 'pentavalue';
  src:  url('#{$pentavalue-font-path}/pentavalue.eot?ot5o80');
  src:  url('#{$pentavalue-font-path}/pentavalue.eot?ot5o80#iefix') format('embedded-opentype'),
  url('#{$pentavalue-font-path}/pentavalue.ttf?ot5o80') format('truetype'),
  url('#{$pentavalue-font-path}/pentavalue.woff?ot5o80') format('woff'),
  url('#{$pentavalue-font-path}/pentavalue.svg?ot5o80#pentavalue') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pentavalue' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon--website {
  &:before {
    content: $icon-icon--website;
  }
}

.icon--3d {
  &:before {
    content: $icon--3d;
  }
}
.icon--add {
  &:before {
    content: $icon--add;
  }
}
.icon--add-square {
  &:before {
    content: $icon--add-square;
  }
}
.icon--add-thin {
  &:before {
    content: $icon--add-thin;
  }
}
.icon--android {
  &:before {
    content: $icon--android;
  }
}
.icon--apple {
  &:before {
    content: $icon--apple;
  }
}
.icon--arow-down {
  &:before {
    content: $icon--arow-down;
  }
}
.icon--arow-left {
  &:before {
    content: $icon--arow-left;
  }
}
.icon--arow-right {
  &:before {
    content: $icon--arow-right;
  }
}
.icon--arow-up {
  &:before {
    content: $icon--arow-up;
  }
}
.icon--behance {
  &:before {
    content: $icon--behance;
  }
}
.icon--blackberry {
  &:before {
    content: $icon--blackberry;
  }
}
.icon--camera {
  &:before {
    content: $icon--camera;
  }
}
.icon--caret-down {
  &:before {
    content: $icon--caret-down;
  }
}
.icon--caret-left {
  &:before {
    content: $icon--caret-left;
  }
}
.icon--caret-right {
  &:before {
    content: $icon--caret-right;
  }
}
.icon--caret-up {
  &:before {
    content: $icon--caret-up;
  }
}
.icon--check {
  &:before {
    content: $icon--check;
  }
}
.icon--check-circle {
  &:before {
    content: $icon--check-circle;
  }
}
.icon--checkhand {
  &:before {
    content: $icon--checkhand;
  }
}
.icon--check-thin {
  &:before {
    content: $icon--check-thin;
  }
}
.icon--cheveron-down {
  &:before {
    content: $icon--cheveron-down;
  }
}
.icon--cheveron-left {
  &:before {
    content: $icon--cheveron-left;
  }
}
.icon--cheveron-right {
  &:before {
    content: $icon--cheveron-right;
  }
}
.icon--cheveron-top {
  &:before {
    content: $icon--cheveron-top;
  }
}
.icon--classroom {
  &:before {
    content: $icon--classroom;
  }
}
.icon--close {
  &:before {
    content: $icon--close;
  }
}
.icon--close-circle {
  &:before {
    content: $icon--close-circle;
  }
}
.icon--cog {
  &:before {
    content: $icon--cog;
  }
}
.icon--conversation {
  &:before {
    content: $icon--conversation;
  }
}
.icon--erp {
  &:before {
    content: $icon--erp;
  }
}
.icon--facebook {
  &:before {
    content: $icon--facebook;
  }
}
.icon--googleplus {
  &:before {
    content: $icon--googleplus;
  }
}
.icon--headphone {
  &:before {
    content: $icon--headphone;
  }
}
.icon--image {
  &:before {
    content: $icon--image;
  }
}
.icon--info {
  &:before {
    content: $icon--info;
  }
}
.icon--joystick {
  &:before {
    content: $icon--joystick;
  }
}
.icon--link {
  &:before {
    content: $icon--link;
  }
}
.icon--linkedin {
  &:before {
    content: $icon--linkedin;
  }
}
.icon--location {
  &:before {
    content: $icon--location;
  }
}
.icon--message {
  &:before {
    content: $icon--message;
  }
}
.icon--message-thin {
  &:before {
    content: $icon--message-thin;
  }
}
.icon--money {
  &:before {
    content: $icon--money;
  }
}
.icon--more-horizontal {
  &:before {
    content: $icon--more-horizontal;
  }
}
.icon--more-vertical {
  &:before {
    content: $icon--more-vertical;
  }
}
.icon--nav {
  &:before {
    content: $icon--nav;
  }
}
.icon--pentavalue {
  &:before {
    content: $icon--pentavalue;
  }
}
.icon--phone {
  &:before {
    content: $icon--phone;
  }
}
.icon--play {
  &:before {
    content: $icon--play;
  }
}
.icon--reloadmore {
  &:before {
    content: $icon--reloadmore;
  }
}
.icon--ribbon {
  &:before {
    content: $icon--ribbon;
  }
}
.icon--search {
  &:before {
    content: $icon--search;
  }
}
.icon--smartphone-l {
  &:before {
    content: $icon--smartphone-l;
  }
}
.icon--smartphone-p {
  &:before {
    content: $icon--smartphone-p;
  }
}
.icon--twitter {
  &:before {
    content: $icon--twitter;
  }
}
.icon--up-down {
  &:before {
    content: $icon--up-down;
  }
}
.icon--users {
  &:before {
    content: $icon--users;
  }
}
.icon--viemio {
  &:before {
    content: $icon--viemio;
  }
}
.icon--windows {
  &:before {
    content: $icon--windows;
  }
}
.icon--youtube {
  &:before {
    content: $icon--youtube;
  }
}

