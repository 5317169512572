//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////General Classes
//////////////////////////////////////////////////////////////////////////////////////////

body {
  background-color: $light_grayo;
  font-family: $Helvetica;
}

body, html {
  overflow-x: hidden !important;
  overflow-y: hidden;
  margin: 0;
  //min-height: 5000px;
}

.img-responsive{
  width: 100%;
}
.clearfix {
  clear: both;
}

.round{
  @include vendor-prefixes(border-radius, 200px);

}

.circle{
  @include vendor-prefixes(border-radius, 50%);
}

.boldText {
  font-weight: bolder;
}

.border_whiteo {
  border: 2px solid $whiteo;
}
img{
  @include selectColors($light_purpleo, $whiteo);
}

.padding_pent_1 {  padding: 5px;  }
.padding_pent_2 {  padding: 10px;  }
.padding_pent_3 {  padding: 15px;  }
.padding_pent_4 {  padding: 20px;  }

.padding_pent_1_r {  padding-right: 5px;  }
.padding_pent_2_r {  padding-right: 10px;  }
.padding_pent_3_r {  padding-right: 15px;  }
.padding_pent_4_r {  padding-right: 20px;  }

.padding_pent_1_l {  padding-left: 5px;  }
.padding_pent_2_l {  padding-left: 10px;  }
.padding_pent_3_l {  padding-left: 15px;  }
.padding_pent_4_l {  padding-left: 20px;  }

.padding_pent_1_t {  padding-top: 5px;  }
.padding_pent_2_t {  padding-top: 10px;  }
.padding_pent_3_t {  padding-top: 15px;  }
.padding_pent_4_t {  padding-top: 20px;  }

.padding_pent_1_b {  padding-bottom: 5px;  }
.padding_pent_2_b {  padding-bottom: 10px;  }
.padding_pent_3_b {  padding-bottom: 15px;  }
.padding_pent_4_b {  padding-bottom: 20px;  }

.margin_pent_1 {  margin: 5px;  }
.margin_pent_2 {  margin: 10px;  }
.margin_pent_3 {  margin: 15px;  }
.margin_pent_4 {  margin: 20px;  }

.margin_pent_1_r {  margin-right: 5px;  }
.margin_pent_2_r {  margin-right: 10px;  }
.margin_pent_3_r {  margin-right: 15px;  }
.margin_pent_4_r {  margin-right: 20px;  }

.margin_pent_1_l {  margin-left: 5px;  }
.margin_pent_2_l {  margin-left: 10px;  }
.margin_pent_3_l {  margin-left: 15px;  }
.margin_pent_4_l {  margin-left: 20px;  }

.margin_pent_1_t {  margin-top: 5px;  }
.margin_pent_2_t {  margin-top: 10px;  }
.margin_pent_3_t {  margin-top: 15px;  }
.margin_pent_4_t {  margin-top: 20px;  }

.margin_pent_1_b {  margin-bottom: 5px;  }
.margin_pent_2_b {  margin-bottom: 10px;  }
.margin_pent_3_b {  margin-bottom: 15px;  }
.margin_pent_4_b {  margin-bottom: 20px;  }


//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// NavBar
//////////////////////////////////////////////////////////////////////////////////////////
.navigation {
  background: rgba(78, 42, 73, 0.001);
  height: 100px;
  padding-top: 35px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 555;
  @include vendor-prefixes(transition, all 0.2s ease-in-out);
  @include userSelect(none);
  a.navbar-brand {
    color: $whiteo;
    position: fixed;
    top: 0;
    left: 125px;
    padding: 30px 14px 6px 14px;
    background: transparent;
    @include vendor-prefixes(transition, all 0.9s ease-in-out);

  }
  ul.nav {
    li {
      a.nav-link {
        color: $whiteo;
        opacity: 0.9;
      }
      .dropdown-menu {
        a.dropdown-item {
          color: $light_purpleo !important;

        }
      }
    }
    li.langinList{
      margin-top: -3px;
    }
    li.searchinList{
      margin-top: 4px;
    }
    li.userlist{
      a.nav-link{
        width: 35px;
        height: 35px;
        @include vendor-prefixes(border-radius, 50%);
        display: inline-block;
        background: #eceaea;
        overflow: hidden;
        padding: 9px 5px;
        text-transform: uppercase;
        text-align: center;
        color: #7d56d8;
        &::after{
          display: none;
          text-align: center;
          margin: -4px auto;
        }
      }
    }
    li.active {
      a.nav-link {
        background: $light_cyano;
        color: $whiteo;
        padding: 5px 10px;
        @include vendor-prefixes(border-radius, 5px 10px);

      }

    }

  }
  button.navbar-toggler {
    color: $whiteo;
  }
}

nav.NavonLight {
  background: rgba(255, 255, 255, 1);
  height: 60px;
  padding-top: 13px;
  width: 100%;
  @include vendor-prefixes(transition, all 0.2s ease-in-out);
  @include vendor-prefixes(box-shadow, 1px 1px 2px 0px rgba(0,0,0,0.1));

  a.navbar-brand {
    color: #d7cfe8  !important;
    position: fixed;
    top: 0;
    left: 125px;
    padding: 13px 14px 6px 14px;
    background: rgba(255, 255, 255, 0.22);
    @include vendor-prefixes(border-radius, 0 0 40px 40px);
    @include vendor-prefixes(transition, all 0.9s ease-in-out);

    i{
      font-size: 32px;
    }
  }
  ul.nav {
    li {
      a.nav-link {
        color: $light_purpleo !important;

      }

    }
    li.active {
      a.nav-link {
        background: $light_cyano;
        color: $whiteo !important;
        padding: 5px 10px;
        @include vendor-prefixes(border-radius, 5px 10px);

      }

    }
  }
  button.navbar-toggler {
    color: $light_purpleo !important;
    font-size: 23px;
    position: absolute;
    right: 13px;
    top: 25px;
  }
}
/*change break point of NavBar*/
@media (min-width: 941px) {
  .navbar-toggleable-dd {
    display: block !important;
  }
  .hidden-md-dd {
    display: none !important;
  }
  .brandFab {
    display: none !important;
  }
  .langFab {
    display: none !important;
  }
  .searchFab {
    display: none !important;
  }
  .userfab{
    display: none !important;
  }
  a.navbar-brand {
    color: $whiteo;
    display: block !important;
    i{
      font-size: 32px;
    }
  }
}
@media (max-width: 940px) {
  .navigation {
    background: rgba(78, 42, 73, 0.001);
    height: 100px;
    padding-top: 35px;
    position: fixed;
    top: 0;
    width: 100%;
    @include vendor-prefixes(transition, all 0.2s ease-in-out);

    a.navbar-brand {
      color: $whiteo;
      display: none !important;
    }
    .langinList {
      display: none !important;
    }
    .searchinList {
      display: none !important;
    }
    .userlist{
      display: none !important;
    }
    .brandFab {
      color: $whiteo;
      position: fixed;
      top: -1px;
      left: 20px;
      font-size: 25px;
      padding: 25px 10px 7px 10px;
      background: transparent;
      @include vendor-prefixes(border-radius, 0 0 16px 16px);

    }
    .langFab {
      color: $whiteo;
      position: absolute;
      top: 25px;
      right: 111px;
      font-size: 18px;
    }
    .searchFab {
      color: $whiteo;
      position: absolute;
      top: 33px;
      right: 70px;
    }
    .userfab{
      position: absolute;
      top: 23px;
      right: 138px;

      a.nav-link{
        width: 30px;
        height: 30px;
        @include vendor-prefixes(border-radius, 50%);
        display: inline-block;
        background: #eceaea;
        overflow: hidden;
        padding: 7px 4px;
        text-transform: uppercase;
        text-align: center;
        color: #7d56d8;
        &::after{
          display: none;
          text-align: center;
          margin: -4px auto;
        }
      }

    }

    ul.nav {
      padding: 15px 0;
      float: none;
      display: block !important;
      li {
        display: block;
        width: 100%;
        text-align: center;
        margin: 4px 0 !important;
        a.nav-link {
          background: $whiteo;
          color: $dark_purpleo;
          padding: 5px 10px;
          @include vendor-prefixes(border-radius, 5px 10px);
          opacity: 0.9;
          text-align: center;
        }
        .dropdown-menu {
          a.dropdown-item {
            color: $light_purpleo !important;

          }
        }
      }
      li.active {
        a.nav-link {
          background: $dark_cyano;
          background: rgb(27, 168, 199);
          background: -moz-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgb(27, 168, 199)), color-stop(100%, rgb(40, 201, 229)));
          background: -webkit-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          background: -o-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          background: -ms-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          background: linear-gradient(to right, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
          color: $whiteo;
          padding: 5px 10px;
          @include vendor-prefixes(border-radius, 5px 10px);
          text-align: center;
        }
      }
    }
    button.navbar-toggler {
      color: $whiteo;
      font-size: 23px;
      position: absolute;
      right: 13px;
      top: 18px;
      outline: 0;
      display: block !important;
    }
  }
  nav.NavonLight {
    background: rgba(255, 255, 255, 1);
    height: 60px;
    padding-top: 35px;
    width: 100%;
    @include vendor-prefixes(transition, top 0.5s ease-in-out);
    @include vendor-prefixes(box-shadow, 1px 1px 9px 0px rgba(0,0,0,0.1));
    .brandFab {
      color: $dark_purpleo;
      position: fixed;
      top: -1px;
      left: 20px;
      font-size: 25px;
      padding: 17px 10px 0px 10px;
      background: $whiteo;
      @include vendor-prefixes(box-shadow, 1px 1px 2px 0px rgba(0,0,0,0.01));
      @include vendor-prefixes(transition, all 0.9s ease-in-out);
      &:hover{
        background:$dark_purpleo ;
        color: $whiteo;
      }
    }
    .langFab {
      color: $light_purpleo;
      position: absolute;
      top: 15px;
      right: 90px;
      font-size: 18px;
    }
    .searchFab {
      color: $light_purpleo;
      position: absolute;
      top: 22px;
      right: 60px;
    }
    .userfab{
      position: absolute;
      top: 15px;
      right: 115px;
    }
    .container {
      min-width: 100% !important;
      max-width: 100% !important;
    }
    ul.nav {
      //margin-top: 45px;
      li {
        a.nav-link {
          color: $light_purpleo !important;
        }
      }
      li.active {
        a.nav-link {
          background: $dark_cyano;
          background: rgb(27, 168, 199);
          background: -moz-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgb(27, 168, 199)), color-stop(100%, rgb(40, 201, 229)));
          background: -webkit-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          background: -o-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          background: -ms-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          background: linear-gradient(to right, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );

          color: $whiteo !important;
          padding: 5px 10px;
          @include vendor-prefixes(border-radius, 5px 10px);
        }

      }
    }
    button.navbar-toggler {
      color: $light_purpleo !important;
      font-size: 19px;
      position: absolute;
      right: 13px;
      top: 11px;
      outline: 0;
      display: block !important;
    }
  }
}
.nav-up {
  top: -100px;
}
#navigation{
  .container{
    @media (max-width: 941px) {
      background: rgba(255,255,255,0.84);
      padding: 0 15px 0px 15px;
      margin-top: 25px;
      max-width: 100% !important
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Loading
//////////////////////////////////////////////////////////////////////////////////////////
.loading-Layer{
  @include linear-gradient(#4c2f66, #452b5d);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 560;
}
svg#Penta-Logo-Animated{
  width:100px;
  height:100px;
  display:block;
  margin:auto;
  margin-top: calc(50vh - 35px);
  padding: 15px;
  border: 1px dashed #39234c;
  @include vendor-prefixes(border-radius,50%);
  @include vendor-prefixes(animation, borderRotate 15s infinite ease-in-out);
  @include keyframes(borderRotate) {
    0% {
      @include vendor-prefixes(border-radius,50%);
      @include vendor-prefixes(transform,rotate(0deg));
      border: 1px dashed #39234c;

    }
    1% {
      @include vendor-prefixes(transform,rotate(360deg));
    }
    2% {
      @include vendor-prefixes(transform,rotate(360deg));
    }
    3% {
      @include vendor-prefixes(transform,rotate(0deg));
    }
    50% {
      @include vendor-prefixes(border-radius,30px);
      border: 1px dashed #5c387a;

    }


    97% {
      @include vendor-prefixes(transform,rotate(0deg));
    }
    98% {
      @include vendor-prefixes(transform,rotate(360deg));
    }
    99% {
      @include vendor-prefixes(transform,rotate(360deg));
    }

    100% {
      @include vendor-prefixes(border-radius,50%);
      @include vendor-prefixes(transform,rotate(0deg));
      border: 1px dashed #39234c;

    }
  }
}
#top-shape-penta{
  fill:#ffffff;
  @include vendor-prefixes(animation, UPdoWn 3s infinite ease-in-out);
  @include vendor-prefixes(transition, all 1s ease-in-out );

  @include keyframes(UPdoWn) {
    0% {
      transform:translateY(12px);
      fill:$light_cyano;

    }
    5%{
      fill:#ffffff;
    }
    10% {
      transform:translateY(0px);
      fill:$dark_cyano;

    }
    20%{
      fill:$light_grayo;
    }
    30%{
      fill:$light_purpleo;
    }
    40%{
      fill:$rgba-pink-strong;
    }
    50%{
      fill:$light_purpleo;
    }
    60%{
      fill:$rgba-red-strong;
    }
    70%{
      fill:$info-color;
    }
    80%{
      fill:$rgba-pink-slight;
    }
    90% {
      transform:translateY(0px);
      fill:$light_grayo;
    }
    100% {
      transform:translateY(12px);
      fill:$light_cyano;
    }
  }
}
#bottom-shape-penta{
  fill:#ffffff;
  @include vendor-prefixes(animation, doWnUP 3s infinite ease-in-out);
  @include vendor-prefixes(transition, all 1s ease-in-out );

  @include keyframes(doWnUP) {
    0% {
      transform:translateY(-5px);
      fill:$light_cyano;

    }
    5%{
      fill:#ffffff;
    }
    10% {
      fill:$dark_cyano;

    }
    20%{
      fill:$light_grayo;
      transform:translateY(0px);

    }
    30%{
      fill:$light_purpleo;
    }
    40%{
      fill:$rgba-pink-strong;
    }
    50%{
      fill:$light_purpleo;
    }
    60%{
      fill:$rgba-red-strong;
    }
    70%{
      fill:$info-color;
    }
    80%{
      fill:$rgba-pink-slight;
      transform:translateY(0px);
    }
    90% {
      fill:$light_grayo;
    }
    100% {
      transform:translateY(-5px);
      fill:$light_cyano;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Search
//////////////////////////////////////////////////////////////////////////////////////////
.mk-fullscreen-search-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, .94);
  text-align: center;
  @include vendor-prefixes(transition, all .25s ease-out);
  @include vendor-prefixes(transform, translateZ(0));
  z-index: 999;
  @include userSelect(none);

  .mk-fullscreen-close {
    position: absolute;
    right: 50px;
    top: 70px;
    font-size: 18px;
    @include vendor-prefixes(transition, all .2s ease-out);
  }
  .mk-fullscreen-close:link {
    color: #fff;

  }
  .mk-fullscreen-close:visited {
    color: #fff;

  }
  .mk-fullscreen-close:hover {
    opacity: 1;
    color: $light_purpleo !important;
    @include vendor-prefixes(transition, all .4s ease-out);
  }
  #mk-fullscreen-search-wrapper {
    display: inline-block;
    max-width: 1000px;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    @include vendor-prefixes(ms-transform, scale(0.9));
    @include vendor-prefixes(transition, all .3s ease-out);
    opacity: 0;
    visibility: hidden;

    form#mk-fullscreen-searchform {
      :focus {
        outline: none;
        border: none;
      }
      position: relative;
      verticle-align: middle;

      input#mk-fullscreen-search-input {
        width: 75%;
        background-color: transparent;
        @include vendor-prefixes(box-shadow, 0 3px 0 0 rgba(255, 255, 255, .1));
        border: 0;
        text-align: center;
        font-size: 35px;
        padding: 20px;
        color: #fff;
        @include vendor-prefixes(transition, all .3s ease-out);
      }
      input#mk-fullscreen-search-input:focus {
        boder: none;
        outline: none;
        box-shadow: 0px 3px 0px 0px $light_purpleo !important;
      }
      i.fullscreen-search-icon {
        font-size: 25px;
        position: absolute;
        right: 15px;
        width: 25px;
        top: 30px;
        color: $whiteo;
        @include vendor-prefixes(transition, all .2s ease-out);
        input {
          position: absolute;
          width: 100px;
          height: 100%;
          background-color: transparent;
          border: 0;
          right: 0;
          top: 0;
        }
      }
      .fullscreen-search-icon:hover {
        border: none;
        outline: none;
        color: $light_purpleo !important;
        @include vendor-prefixes(transition, all .2s ease-out);
      }
      .fullscreen-search-icon:focus {
        border: none;
        outline: none;
        color: $light_purpleo !important;
        @include vendor-prefixes(transition, all .2s ease-out);
      }
    }
  }
  &-show {
    visibility: visible;
    opacity: 1;
    #mk-fullscreen-search-wrapper {
      opacity: 1;
      visibility: visible;
      @include vendor-prefixes(ms-transform, scale(1));
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// cover Text
//////////////////////////////////////////////////////////////////////////////////////////
.cover-st-default {
  height: 92vh;
  min-height: 590px;
  @media only screen and (max-width: 768px) {
    min-height: 490px;
    height: 67vh;
  }
  background-color: $whiteo;
  @include userSelect(none);

  /* ----------- iPad  ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {min-height: 440px; height: 440px;}
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {min-height: 440px; height: 440px;  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {min-height: 440px; height: 440px;}
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {min-height: 440px; height: 440px;}
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {min-height: 440px; height: 440px;}
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {min-height: 440px; height: 440px;}
}
.titleWizSlogan{
  @include userSelect(none);
  padding-top: 250px;

  @media (max-width: 768px) {
    padding-top: 180px;

  }

  h1.AnimateHeader{
    font-size: 45px;
    font-weight: bold;
    //text-transform: capitalize;
    span{
      z-index: 50;
    }
  }
  p.AnimateSmall{
    letter-spacing: 4px;
    //text-indent: 5px;
    span{
      z-index: 50;
    }
  }

}
.btn_style_1{
  border:2px solid $whiteo;
  padding: 8px 15px;
  margin-top: 15px;
  color: $whiteo;
  @include vendor-prefixes(border-radius, 200px);
  @include vendor-prefixes(transition, all 0.6s ease-in-out);
  @include userSelect(none);

  font-size: 18px;
  text-decoration:none;
  i{
    font-size: 14px;
    @include vendor-prefixes(transition, all 0.6s ease-in-out);
  }
  &:hover {
    @include vendor-prefixes(transition, all 0.6s ease-in-out);
    background: $light_cyano;
    color: $whiteo;
    i{
      margin-left: 8px;
    }

  }
}
.btn_more_w{
  border:2px solid $whiteo;
  padding: 1px 10px;
  color: $whiteo;
  @include vendor-prefixes(border-radius, 200px);
  @include vendor-prefixes(transition, all 0.6s ease-in-out);
  font-size: 18px;
  text-decoration:none;
  display: block;
  max-width: 85px;
  min-width: 90px;
  margin: 15px auto;
  @include userSelect(none);

  &:hover{
    background:  $whiteo;
    letter-spacing: 10px;
    color: $dark_purpleo !important;
    text-indent: 10px;
  }
}
.btn_more_b{
  border:2px solid $dark_purpleo;
  padding: 1px 10px;
  color: $dark_purpleo;
  @include vendor-prefixes(border-radius, 200px);
  @include vendor-prefixes(transition, all 0.6s ease-in-out);
  font-size: 18px;
  text-decoration:none;
  display: block;
  max-width: 85px;
  min-width: 90px;
  margin: 15px auto;
  @include userSelect(none);

  &:hover{
    background: $dark_purpleo;
    letter-spacing: 10px;
    color: $whiteo !important;
    text-indent: 10px;
  }
}
.BlocksTitles{
  font-size: 35px;
  font-weight: bold;
  letter-spacing: -1px;
  @include userSelect(none);

  small.slogan{
    font-size: 17px;
    font-weight: 100;
    letter-spacing: 10px;
    text-indent: 10px;
    display: block;
  }

}
.pStyle {
  font-size: 16px;
  letter-spacing: 0;
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Works Done Masonery
//////////////////////////////////////////////////////////////////////////////////////////
.worksDone{
  background-color: #f1f5f7;
  padding-top: 50px;
  &__title{
    font-weight: bold;
    font-size: 35px;
    @include userSelect(none);

  }
  &__slogan{
    letter-spacing: 8px;
    text-indent: 8px;
    @include userSelect(none);

  }
  .masonryWrapper{
    .grid{
      .masoImgContainer{
        position: relative;
        overflow: hidden;
        .mainImg{
          width: 100%;
          z-index: 2;

        }
        .curveMason {
          position: absolute;
          right: 0;
          left: 0;
          z-index: 3;
          width: 103%;
          margin-top: -19px;
          @media only screen and (max-width : 480px) {

            width: 103%;
            margin-top: 0px;
          }
        }
      }
      .masoTxtContainer{
        right: 0;
        left: 0;
        z-index: 2;
        background:  #fff ;
        background-size: contain;
        padding: 15px;
        h3{
          font-size: 19px;
          font-weight: 500;
        }
        .platform{
          background: #372644;
          @include vendor-prefixes(border-radius, 50px);
          padding:  5px 5px 0px 5px;
          color: #fff;
          i{
            font-size: 19px;
            margin: auto 3px;
          }
        }
      }
    }

  }
}
.load-More{
  background-color: #000000;
  @include linear-gradient(#4c2f66, #452b5d);
  @include vendor-prefixes(border-radius, 50%);
  color: $whiteo !important;
  width: 50px;
  height: 50px;
  padding: 17px 12px 15px 15px;
  display: block;
  margin: 15px auto;
  &:hover{
    @include linear-gradient(#452b5d, #4c2f66);
    @include vendor-prefixes(transform, rotate(360deg));
    @include vendor-prefixes(transition, all 1s ease-in-out);
  }
}
.load-More-bottom{
  display: block;
  @include userSelect(none);

  //margin-bottom: 90px;
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Request idea
//////////////////////////////////////////////////////////////////////////////////////////
.requestIdea{
  //margin-top: 165px;
  margin-top: 30px;
  margin-bottom: 30px;
  .requestIdeatop{
    background: #f1f5f7 url("../img/requestBgTop.svg")  center bottom no-repeat;
    background-size: 105%;
    min-height: 40px;
    margin-bottom: -2px;
  }
  .requestIdeaContent{
    background: url(../img/lamp.png) center right no-repeat,
    $whiteo url(../img/requestBgBottom.svg) center bottom no-repeat;
    background-size: 44% , 103%;
    min-height: 300px;
    padding-top: 50px;

    @media (min-width: 1200px) {
      background-size: 44% , 103%;
    }
    @media (max-width: 1200px) {
      background-size: 44% , 103%;
    }
    @media (max-width: 992px) {
      background-size: 44% , 103%;
    }
    @media (max-width: 768px) {
      background-size: 130% , 103%;
    }
    @media (max-width: 480px) {
      background-size: 174% , 103%;
    }
    @media (max-width: 320px) {
      background-size: 100% , 103%;
    }
    @media (max-width: 100px) {
      background-size: 100% , 103%;
    }


    h2{
      text-transform: capitalize;
      font-weight: bolder;
      @include userSelect(none);
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    p{
      letter-spacing: 6px;
      @include userSelect(none);
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    a{
      @include vendor-prefixes(transition, all 0.3s ease-in-out);
      background: #f8fafb;
      padding: 5px 5px 5px 9px;
      width: 200px;
      display: block;
      border-radius: 505px;
      @include vendor-prefixes(box-shadow, -1px 0px 7px 1px rgba(0, 0, 0, 0.06));
      @include userSelect(none);
      border: 1px #fff solid;
      color: #373a3c;
      span{
        @include vendor-prefixes(transition, all 0.3s ease-in-out);
        background: #fff;
        padding: 15px 10px 15px 15px;
        @include vendor-prefixes(border-radius, 50% );
        border: 1px #fff solid;
        text-align: center;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        color: #c3c4c3;
        display: inline-block;
        @include vendor-prefixes(box-shadow, 0px 0px 7px 1px rgba(0, 0, 0, 0.06));
      }
      &:hover{
        border: 1px #a8afb3 solid;
        color: #a8afb3;

        span{
          border: 1px #a8afb3 solid;
          color: #a8afb3;
        }
      }
      @media (max-width: 768px) {
        text-align: center;
        margin: 25px auto 15px auto;
      }
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// another Service
//////////////////////////////////////////////////////////////////////////////////////////
.anotherService{
  background: url(../img/anotherservices-left.svg) center left no-repeat,
  url(../img/anotherservices-right.svg) center right no-repeat;
  background-size: 20% , 20%;
  @include userSelect(none);
  margin-bottom: 50px;

  &__title{

  }
  &__slogan{

  }
  img{
    width: 100%;
    margin-top: 15px;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Video
//////////////////////////////////////////////////////////////////////////////////////////
.videoSection {
  background-color: #f1f5f7;
  padding-top: 50px;
  &__title {
    font-weight: bold;
    font-size: 35px;
    @include userSelect(none);

  }
  &__slogan {
    letter-spacing: 8px;
    text-indent: 8px;
    @include userSelect(none);

  }
  .video{
    max-height: 300px;
    overflow: hidden;
    @include userSelect(none);

    iframe{
      height: 300px;
    }
  }

}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// technology Section
//////////////////////////////////////////////////////////////////////////////////////////
.technologySection {
  background-color: #f1f5f7;
  padding-top: 50px;
  padding-bottom: 50px;
  &__title {
    font-weight: bold;
    font-size: 35px;
    @include userSelect(none);

  }
  &__slogan {
    letter-spacing: 8px;
    text-indent: 8px;
    @include userSelect(none);

  }
  .video{
    max-height: 300px;
    overflow: hidden;
    iframe{
      height: 300px;
    }
  }
  .tech{
    @include userSelect(none);
    padding: 10px;
    display: table;
    @include vendor-prefixes(border-radius, 10px);
    min-height: 82px;
    margin:10px auto;
    height: 82px;
    width: 100%;
    background-color: $whiteo;
    border: 1px solid $whiteo;
    @include vendor-prefixes(border-radius,15px);
    @include vendor-prefixes(transition, all 0.9s ease-in-out);
    @include vendor-prefixes(filter, grayscale(1));
    @include opacity(opacity, 0.9);
    &:hover{
      border: 1px solid #ced2d4;
      @include vendor-prefixes(filter, grayscale(0.5));
    }
    span{
      display: table-cell;
      vertical-align: middle;
      img{
        width: 85%;
        text-align: center;
        margin: auto;
        display: block;
      }
    }

  }

}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// intro
//////////////////////////////////////////////////////////////////////////////////////////
.introDiv{
  background: #ffffff;
  text-align: center;
  margin: 20px auto;
  padding: 30px;
  h2{
    font-weight: bold;
    @include userSelect(none);
  }
  p{
    @include selectColors($dark_purpleo, $whiteo);
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// fourBlocks
//////////////////////////////////////////////////////////////////////////////////////////
.fourBlocks{
  @include userSelect(none);

  background: url(../img/anotherservices-left.svg) center left no-repeat,
  url(../img/anotherservices-right.svg) center right no-repeat;
  background-size: 5% , 5%;
  margin: 20px auto;
  text-align: center;
  h2{
    font-weight: bold;
    margin-bottom: 20px;
  }
  .blocks{
    background: $light_purpleo;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    margin-bottom: 15px;
    img{
      width: 80%;
      margin-bottom: 25px;
      @include vendor-prefixes(border-radius, 50%);
      overflow: hidden;
    }
    h5{
      color: #fff;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// screenshoot
//////////////////////////////////////////////////////////////////////////////////////////
.screenshoot {
  @include userSelect(none);
  background:  url("../img/bg-slices.svg") left center no-repeat  ;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #fff;
  margin: 20px auto;

  padding-bottom: 20px;
  .item{
    padding: 15px;
    img{
      width: 100%;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// footer
//////////////////////////////////////////////////////////////////////////////////////////
footer {
  color: $whiteo;
  margin-top: 20px;
  .footerCurve {
    background:  url("../img/footerCurve.svg") center bottom no-repeat  ;
    background-size: cover;
    //width: 105%;
    min-height: 35px;
    @include userSelect(none);
    margin-bottom: -1px;
  }
  .footerContent{
    text-align: center;
    background: $light_purpleo;
    //padding-bottom: 8px;
    img{
      margin: -30px auto 10px auto;
      @include userSelect(none);
    }
    p{
      margin-bottom: 5px;
      @include userSelect(none);

    }
    ul{
      &.socialLinks{
        margin-bottom: 3px;
        @include selectColors($dark_purpleo, $whiteo);
        @include userSelect(none);

        li{
          display: inline-block;
          width: 35px;
          height: 35px;
          @include userSelect(none);

          @include vendor-prefixes(border-radius, 50%);
          @include vendor-prefixes(transition, all 0.6s ease-in-out);
          background: rgba(255,255,255,0);
          padding: 8px;
          &:hover{
            text-decoration: none;
            background: $dark_purpleo;
            color: $whiteo;
          }

          a{
            text-decoration: none;
            color: $whiteo;
            &:hover{
              text-decoration: none;
              color: $whiteo;

            }
          }
        }
      }
      &.webMap{
        background: rgba(0, 0, 0, 0.05);
        padding: 10px;
        margin-bottom: 0;
        li{
          display: inline-block;
          margin: auto 7px;
          a{
            text-decoration: none;
            color: $whiteo;
            @include selectColors($dark_purpleo, $whiteo);

          }
        }
      }
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Modals
//////////////////////////////////////////////////////////////////////////////////////////
.requestquota, .subscribe{
  background: #ffffff;
  overflow-x: hidden;
  .closestyle{
    background: $light_purpleo;
    text-align: center;
    margin-bottom: 50px;
    padding: 10px;
    color: $whiteo;
    cursor: pointer;
    @include vendor-prefixes(transition, all 1s ease-in-out);
    &:hover{
      background: $dark_purpleo;
    }
  }
  &__title{
    font-weight: bold;
    font-size: 35px;
    @include userSelect(none);
    margin-bottom: 0;
  }
  &__slogan{
    margin-top: -1px;
    @include userSelect(none);
    letter-spacing: 8px;
    text-indent: 8px;

  }
  .modal-content{
    padding-right: 20px;
    border: 0;
    input, textarea, select {
      background: #f1f5f7;
      @include vendor-prefixes(border-radius, 50px);
      padding: 2px 3px 2px 20px;
      font-size: 14px;
      margin-bottom: 5px;
      margin-top: 5px;
      border: 1px solid transparent;
      @include vendor-prefixes(transition, all 1s ease-in-out);
      max-width: 100%;
      @media (min-width: 1200px) {
        max-width: 100%;
      }
    }
    input:focus:not([readonly]){
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
      border: 1px solid #d4d8da;
    }
    select{
      min-width: 100%;
      min-height: 40px;
      padding: 7px 15px 5px 15px;
      display: block;
    }
    select:focus{
      outline: none;
      border: 1px solid #d4d8da;

    }
    textarea{
      @include vendor-prefixes(border-radius, 15px);
      min-height: 100px;
      max-height: 220px;
    }
    textarea:focus:not([readonly]){
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
      border: 1px solid #d4d8da;
      outline: 0;
    }

    a{
      font-size: 14px;
      text-align: left;
    }
    button{
      display: block;
      background: $light_purpleo;
      width: 100%;
      max-width: 375px;
      margin: 14px auto;
      border-radius: 100px;
      min-height: 42px;
      outline: 0;
      border:2px solid $whiteo;
      padding: 2px;
      color: $whiteo;
      @include vendor-prefixes(border-radius, 200px);
      @include vendor-prefixes(transition, all 0.6s ease-in-out);
      font-size: 15px;
      text-decoration:none;
      position: relative;

      i{
        font-size: 14px;
        @include vendor-prefixes(transition, all 0.6s ease-in-out);
      }
      &:hover {
        @include vendor-prefixes(transition, all 0.6s ease-in-out);
        background: $dark_purpleo;
        color: $whiteo;
        i{
          margin-left: 8px;
        }
      }
    }
    img{
      width: 100%;
    }
  }
}
.subscribe{
  .closestyle{
    background: $light_cyano;
    &:hover{
      background: $dark_cyano;
    }
  }
  .modal-content{
    button{
      background: $light_cyano;
      &:hover {
        background: $dark_cyano;

      }
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Canvases
//////////////////////////////////////////////////////////////////////////////////////////
#canvasSnow{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.19;
  z-index: 1;
  max-height: 90vh;
}
#canvasXo {
  width: 100%;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

}