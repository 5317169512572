/*about Page*/
.txttype-cover {
  height: 590px;
  background: #8155db;
  background-size: contain;
  @media (min-width: 1300px) and (max-width: 1400px ){
    height: auto;
  }

  .titleWizSloganContainer {
    .titleWizSlogan {
      h1 {

      }
      p {

      }
      .downarrow{
        margin-top: 30px;
        position: absolute;
        margin: auto;
        text-align: center;
        display: block;
        right: 0;
        left: 0;
        z-index: 3;
        i{
          color: #8155db;
          background: #fff;
          width: 55px;
          height: 55px;
          display: block;
          margin: 15px auto auto auto;
          padding: 15px;
          @include vendor-prefixes(border-radius,50%);
          border: 5px solid rgba(79, 31, 115, 0.3);
          @include vendor-prefixes(transition, all 1s ease-in-out);
        }
        span.downs{
          margin: 9px auto auto auto;
          display: block;
          color: #fff;
          font-size: 14px;
          text-transform: uppercase;
          opacity: 0;
          @include vendor-prefixes(transition, all 1s ease-in-out);

        }
      }
      .downarrow:hover .downs{
        opacity: 1;
      }
    }
  }
}

/*All solutions */
.txttypebody {
  background: url("../img/partners-all-bg.png") center bottom no-repeat;
  background-size: contain;
  .alltxttype {
    padding-top: 50px;
    .block{
      margin: 20px auto 65px auto;
      h2{
        text-align: left;
        font-size: 23px;
        text-transform: capitalize;
        font-weight: 600;
      }
      p{
        font-size: 14px;
      }
    }

  }
}