
/*about Page*/
.solutionPage-cover{
  height: 650px;
  background:  url("../img/cover-solutions.svg")  center center no-repeat;
  background-size: cover;
  .titleWizSloganContainer{
    .titleWizSlogan{
      h1{

      }
      p{

      }
    }
  }
  .coverAvatar{
    width: 315px;
    margin-top: 170px;
    @media only screen and (max-width : 768px) {
      margin: 15px auto auto auto;
      display: block;
      width: 220px;
    }
    @media only screen and (max-width : 320px) {
      margin: 15px auto auto auto;
      display: block;
      width: 220px;
    }
    /* ----------- iPad  ----------- */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {margin-top: 170px;}
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {margin-top: 170px;  }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {margin-top: 170px;}
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {margin-top: 170px;}
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {margin-top: 170px;}
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {margin-top: 170px;}
    .Lamp--0{
      fill:#51E2FB;
    }
    .Lamp--1{
      fill:#4C4C4C;
    }
    .Lamp--2{
      fill:#444444;
    }
  }
}

/*All solutions */
.SolutionsBody{
  background:  url("../img/solution-all-bg.png")  center bottom no-repeat;
  background-size: contain;
  .AllsolutionsDiv{
    @include userSelect(none);
    img{
      width: 100%;
      margin: 15px auto;
      @include vendor-prefixes(border-radius, 15px );

    }
  }
}
