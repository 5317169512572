// BUTTONS

.btn {
    border-radius: 0;
    border: 0;
    @extend .z-depth-1;
    transition: .2s ease-out;
    color: #fff;
    margin: 6px;
    &:hover {
        @extend .z-depth-1-half;
        color: #fff;
    }
    &:active,
    &:focus,
    &.active {
        outline: 0;
        color: #fff;
    }
    .fa {
        margin-top: 3px;
        &.right {
            margin-left: 3px;
        }
        &.left {
            margin-right: 3px;
        }
    }
}

.btn-group {
    .btn {
        margin: 0;
    }
}

// Alert buttons
.btn-secondary {
    background-color: $secondary-color;
    &:hover,
    &:focus {
        background-color: lighten( $secondary-color, 5%)!important; 
        color: #fff;
    }
    &.active {
        background-color: darken( $secondary-color, 20%)!important;
        @extend .z-depth-1-half;
        &:hover {
            color: #fff;
        }
        &:focus {
            color: #fff;
        }
    }
    &.dropdown-toggle {
        color: #fff!important;
        background-color: $secondary-color!important;
        @extend .z-depth-1-half;
        &:hover {
            color: #fff;
            background-color: lighten( $secondary-color, 5%)!important; 
        }
        &:focus {
            color: #fff;
            background-color: $secondary-color;
        }
    }
}

.btn-secondary:active:hover {
    background-color: lighten( $secondary-color, 5%)!important;
    color: #fff; 
}

.btn-primary {
    background: $primary-color;
    &:hover,
    &:focus {
        background-color: lighten( $primary-color, 5%)!important;
    }
    &.active {
        background-color: darken( $primary-color, 20%)!important;
        @extend .z-depth-1-half;
    }
}

.btn-default {
    background: $default-color;
    &:hover,
    &:focus {
        background-color: lighten( $default-color, 5%)!important;
    }
    &.active {
        background-color: darken( $default-color, 20%)!important;
        @extend .z-depth-1-half;
    }
}

.btn-success {
    background: $success-color;
    &:hover,
    &:focus {
        background-color: lighten( $success-color, 2%)!important;
    }
    &.active {
        background-color: darken( $success-color, 20%)!important;
        @extend .z-depth-1-half;
    }
}

.btn-info {
    background: $info-color;
    &:hover,
    &:focus {
        background-color: lighten( $info-color, 5%)!important;
    }
    &.active {
        background-color: darken( $info-color, 20%)!important;
        @extend .z-depth-1-half;
    }
}

.btn-warning {
    background: $warning-color-dark;
    &:hover,
    &:focus {
        background-color: lighten( $warning-color-dark, 6%)!important;
    }
    &.active {
        background-color: darken( $warning-color, 20%)!important;
        @extend .z-depth-1-half;
    }
}

.btn-danger {
    background: $danger-color-dark;
    &:hover,
    &:focus {
        background-color: lighten( $danger-color-dark, 3%)!important;
    }
    &.active {
        background-color: darken( $danger-color-dark, 15%)!important;
        @extend .z-depth-1-half;
    }
}

.btn-link {
    background-color: transparent;
    color: #000;
    &:hover,
    &:focus{
        background-color: transparent;
        color: #000;
    }
}

// Additional button styles
.btn-primary-outline {
    border: 2px solid $primary-color;
    color: $primary-color-dark;
    &:hover,
    &:focus {
        background-color: transparent;
        color: $primary-color;
    }
}

.btn-secondary-outline {
    border: 2px solid $secondary-color;
    color: $secondary-color-dark;
    &:hover,
    &:focus {
        background-color: transparent;
        color: $secondary-color;
        border: 2px solid $secondary-color;
    }
}

.btn-default-outline {
    background-color: transparent;
    border: 2px solid $default-color;
    color: $default-color-dark;
    &:hover,
    &:focus {
        background-color: transparent;
        color: $default-color;
    }
}

.btn-success-outline {
    border: 2px solid $success-color;
    color: $success-color-dark;
    &:hover,
    &:focus {
        background-color: transparent;
        color: $success-color;
    }
}

.btn-info-outline {
    border: 2px solid $info-color;
    color: $info-color-dark;
    &:hover,
    &:focus {
        background-color: transparent;
        color: $info-color;
    }
}

.btn-warning-outline {
    border: 2px solid $warning-color;
    color: $warning-color-dark;
    &:hover,
    &:focus {
        background-color: transparent;
        color: $warning-color;
    }
}

.btn-danger-outline {
    border: 2px solid $danger-color;
    color: $danger-color-dark;
    &:hover,
    &:focus {
        background-color: transparent;
        color: $danger-color;
    }
}

// Pagination

.pager li a {
    border: 0;
    @extend .z-depth-1;
    transition: .2s ease-out;
    &:hover {
        @extend .z-depth-1-half;
    }
}