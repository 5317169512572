// The dropdown wrapper (`<div>`)
.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle {
  // Generate the caret automatically
  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: .25rem;
    margin-left: .25rem;
    vertical-align: middle;
    content: "";
    border-top: $caret-width solid;
    border-right: $caret-width solid transparent;
    border-left: $caret-width solid transparent;
  }

  // Prevent the focus on the dropdown toggle when closing dropdowns
  &:focus {
    outline: 0;
  }
}

.dropup {
  .dropdown-toggle {
    &::after {
      border-top: 0;
      border-bottom: $caret-width solid;
    }
  }
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0; // override default ul
  font-size: $font-size-base;
  color: $body-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($border-radius);
  @include box-shadow(0 6px 12px rgba(0,0,0,.175));
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  @include nav-divider($dropdown-divider-bg);
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: $line-height;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background: none; // For `<button>`s
  border: 0; // For `<button>`s

  @include hover-focus {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    background-color: $dropdown-link-hover-bg;
  }

  // Active state
  &.active {
    @include plain-hover-focus {
      color: $dropdown-link-active-color;
      text-decoration: none;
      background-color: $dropdown-link-active-bg;
      outline: 0;
    }
  }

  // Disabled state
  //
  // Gray out text and ensure the hover/focus state remains gray
  &.disabled {
    @include plain-hover-focus {
      color: $dropdown-link-disabled-color;
    }

    // Nuke hover/focus effects
    @include hover-focus {
      text-decoration: none;
      cursor: $cursor-disabled;
      background-color: transparent;
      background-image: none; // Remove CSS gradient
      @include reset-filter();
    }
  }
}

// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  right: 0;
  left: auto; // Reset the default from `.dropdown-menu`
}
// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  right: auto;
  left: 0;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: $font-size-sm;
  line-height: $line-height;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ($zindex-dropdown - 10);
}

// Right aligned dropdowns
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set.

.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    content: "";
    border-top: 0;
    border-bottom: $caret-width solid;
  }

  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
}
