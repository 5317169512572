/*about Page*/
.industriespage-cover {
  position: relative;
  height: 580px;
  background: url("../img/cover-partner.svg") top center no-repeat;
  background-size: contain;
  @media (max-width: 1300px) {
    background-size: cover;
  }
  @media (max-width: 768px) {
    height: 580px;
  }
  @media (max-width: 480px) {
    height: 630px;
  }

  .titleWizSloganContainer {
    z-index: 3;
    .titleWizSlogan {
      h1 {

      }
      p{

      }
    }
  }
  .winch  {
    width: 387px;
    z-index: 2;
    margin: auto;
    margin-top: 115px;
    text-align: center;
    display: block;

    @media (max-width: 533px) {
      width: 100%;
      margin-top: 20px;
    }
    @media (min-width: 1450px) {
      margin-top: 205px;
    }

    .idustrywinch0{display:none;}
    .idustrywinch1{display:inline;opacity:0.2;fill:#FFFFFF;}
    .idustrywinch2{display:inline;opacity:0.2;}
    .idustrywinch3{fill:#FFFFFF;}
    .idustrywinch4{fill:#44403E;}
    .idustrywinch5{fill:#28C6C6;}
    .idustrywinch6{fill:#6C707E;}
    .idustrywinch7{fill:#586273;}
    .idustrywinch8{opacity:0.3;fill:#BDCAD1;}
    .idustrywinch9{opacity:0.3;fill:#FFFFFF;}
    .idustrywinch10{fill:#D6D6D6;}
    .idustrywinch11{fill:#FFFEFF;}
    .idustrywinch12{opacity:0.2;fill:#F7F7F7;}
    .idustrywinch13{fill:#C69C6D;}
    .idustrywinch14{fill:#8C6239;}
    .idustrywinch15{fill:#4A5361;}
    .idustrywinch16{fill:#4D4D4D;}
    .idustrywinch17{fill:#F2F2F2;}
    .idustrywinch18{fill:#28D4F1;}
    .idustrywinch19{fill:#9784D3;}

    #blackcircle {
      animation: circlecar 10s infinite ease;
    }
    #whitecircle {
      animation: circlecar 10s infinite ease;
    }
    #handle{
      animation: handle 70s infinite ease;

    }
  }
}

@include keyframes(circlecar) {
  0% {
    transform: translate(0px , 0px);
  }
  25% {
    transform: translate(11px , -8px);
  }
  50% {
    transform: translate(3px , 10px);
  }
  100% {
    transform: translate(0px , 0px);
  }
}

@include keyframes(handle) {
  0% {
    transform: translate(0px , 0px);
  }
  25% {
    transform: translate(-13px , 33px);
  }
  50% {
    transform: translate(6px , -10px);
  }
  100% {
    transform: translate(0px , 0px);
  }
}

/*All solutions */
.industriesbody {
  background: url("../img/partners-all-bg.png") center bottom no-repeat;
  background-size: contain;
  @include userSelect(none);
  .allindustries {
    a.industry{
      background-color: $whiteo;
      display: block;
      overflow: hidden;
      @include vendor-prefixes(border-radius,8px);
      @include vendor-prefixes(transition, all 0.7s ease-in-out);
      margin-bottom: 15px;
      border: 1px solid $whiteo;
      text-align: center;
      img{
        width: 100%;
      }
      h3{
        color: $blacko;
        font-size: 18px;
        margin: 7px auto;
        text-transform: capitalize;

      }

      @include vendor-prefixes(filter, grayscale(0.5)brightness(1.01));
      @include opacity(opacity, 0.9);

      &:hover{
        border: 1px solid #ced2d4;

        @include vendor-prefixes(filter, grayscale(0)brightness(1));
        h3{
          color: $dark_purpleo;
        }
      }

    }

  }
}

/*********************************/
/********All solutions ***********/
/*********************************/

.industrydetails-cover {
  //height: 590px;
  background: #8155db;
  background-size: cover;
  //background-size: contain;
  overflow: hidden;
  @media (min-width: 1300px) and (max-width: 1400px ){
    height: 100vh;
  }

  .titleWizSloganContainer {
    .titleWizSlogan {
      h1 {

      }
      p {

      }
      img{
        width: 100%;
        @include vendor-prefixes(border-radius,15px 15px 0 0);
        z-index: 3;
      }
    }
  }
}
