// Helvetica FONT 

@font-face {
    font-family: "Helvetica Neue W23 for SKY Reg";
    src: local(Helvetica Neue W23 for SKY Reg), url('#{$helvetica-font-path}Helvetica Neue W23 for SKY Reg.eot');
    src: url('#{$helvetica-font-path}HelveticaNeueW23forSKY-Reg.eot?#iefix') format('embedded-opentype'),
    url('#{$helvetica-font-path}HelveticaNeueW23forSKY-Reg.woff2') format('woff2'),
    url('#{$helvetica-font-path}HelveticaNeueW23forSKY-Reg.woff') format('woff'),
    url('#{$helvetica-font-path}HelveticaNeueW23forSKY-Reg.ttf') format('truetype'),
    url('#{$helvetica-font-path}HelveticaNeueW23forSKY-Reg.svg#HelveticaNeueW23forSKY-Reg') format('svg');
    font-weight: normal;
    font-style: normal;
}
    // font-family: 'Helvetica Neue W23 for SKY Reg';
