/*
 * Company website  :http://pentavalue.com *
 * Graphic and Web Designer : George Samuel *
 * Full Stack Developers :   and     *
 * Material Design for Bootstrap 4 *
 * Version: MDB FREE 4.0.0
 * Website  : http://www.pentavalue.com
 * Lang : ENglish
 */

@charset "UTF-8";


// BOOTSTRAP -- ENglish //*/
@import "bootstrap_ltr/variables";
@import "bootstrap_ltr/mixins";
@import "bootstrap_ltr/normalize";
@import "bootstrap_ltr/print";
@import "bootstrap_ltr/reboot";
@import "bootstrap_ltr/type";
@import "bootstrap_ltr/images";
@import "bootstrap_ltr/code";
@import "bootstrap_ltr/grid";
@import "bootstrap_ltr/tables";
@import "bootstrap_ltr/forms";
@import "bootstrap_ltr/buttons";
@import "bootstrap_ltr/animation";
@import "bootstrap_ltr/dropdown";
@import "bootstrap_ltr/button-group";
@import "bootstrap_ltr/input-group";
@import "bootstrap_ltr/custom-forms";
@import "bootstrap_ltr/nav";
@import "bootstrap_ltr/navbar";
@import "bootstrap_ltr/card";
@import "bootstrap_ltr/breadcrumb";
@import "bootstrap_ltr/pagination";
@import "bootstrap_ltr/pager";
@import "bootstrap_ltr/labels";
@import "bootstrap_ltr/jumbotron";
@import "bootstrap_ltr/alert";
@import "bootstrap_ltr/progress";
@import "bootstrap_ltr/media";
@import "bootstrap_ltr/list-group";
@import "bootstrap_ltr/responsive-embed";
@import "bootstrap_ltr/modal";
@import "bootstrap_ltr/tooltip";
@import "bootstrap_ltr/popover";
@import "bootstrap_ltr/carousel";
@import "bootstrap_ltr/utilities";
@import "bootstrap_ltr/utilities-background";
@import "bootstrap_ltr/utilities-spacing";
@import "bootstrap_ltr/utilities-responsive";

// mdb_rtl -- ENGLISH //
@import "mdb_ltr/data/prefixer";
@import "mdb_ltr/data/mixins";
@import "mdb_ltr/data/mixinsCustom";
@import "mdb_ltr/data/colors";
@import "mdb_ltr/data/colorsCustom";
@import "mdb_ltr/data/variables-b4";
@import "mdb_ltr/data/variables";
@import "mdb_ltr/data/variablesCustom";
@import "mdb_ltr/global";
@import "mdb_ltr/helvetica";
@import "mdb_ltr/typography";
@import "mdb_ltr/animations";
@import "mdb_ltr/waves";
@import "mdb_ltr/helpers";
@import "mdb_ltr/buttons";
@import "mdb_ltr/forms-basic";
@import "mdb_ltr/cards-basic";
@import "mdb_ltr/navbars";
@import "mdb_ltr/hover-effects";
@import "mdb_ltr/footer";
@import "mdb_ltr/carousels-basic";

//plugins
@import "lib/animate";
@import "lib/owl.carousel";
@import "lib/owl.theme";
@import "lib/lity";

//iconFont
@import "lib/penta-font";

//custom style by George //
/*General Style*/
@import "english_style/general";

/*Home Style*/
@import "english_style/index";

/*services Style*/
@import "english_style/services";

/*services Mobile Style*/
@import "english_style/services_mob";

/*services Web Style*/
@import "english_style/services_web";

/*services 3d Style*/
@import "english_style/services_3d";

/*services games Style*/
@import "english_style/services_games";

/*services multimedia Style*/
@import "english_style/services_multimedia";

/*services Ux Style*/
@import "english_style/services_ux";

/*services Digital Marketing Style*/
@import "english_style/services_digitalmarkiting";

/*services Interior Design Style*/
@import "english_style/services_interiordesign";

/*services courses Style*/
@import "english_style/services_courses";

/*services vr Style*/
@import "english_style/services_vr";

/*services AR Style*/
@import "english_style/services_ar";

/*services erp Style*/
@import "english_style/services_erp";

/*services about Style*/
@import "english_style/about";

/*Contact Style*/
@import "english_style/contact";

/*Solution Style*/
@import "english_style/solutions";

/*Solution-details Style*/
@import "english_style/solutions_details";

/*Partner Style*/
@import "english_style/partner";

/*Portfolio Style*/
@import "english_style/portfolio";

/*Portfolio Style*/
@import "english_style/policy";

/*Portfolio Style*/
@import "english_style/industries";

/*Ticket Style*/
@import "english_style/ticket";