//Mixins Custom


////////////////////////////////////////////////////////////////////////////vendor prefixe

@mixin vendor-prefixes($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  -khtml-#{$property}: $value;
  #{$property}: $value;
}

//@include vendor-prefixes(property, value);


////////////////////////////////////////////////////////////////////////////keyframe
@mixin keyframes($animationName)
{
  @-webkit-keyframes #{$animationName} {
	@content;
  }
  @-moz-keyframes #{$animationName}  {
	@content;
  }
  @-o-keyframes #{$animationName} {
	@content;
  }
  @-ms-keyframes #{$animationName} {
	@content;
  }
  @keyframes #{$animationName} {
	@content;
  }

}

//@include keyframes(slide-down) {
//  0% { opacity: 1; }
//  90% { opacity: 0; }
//}



//////////////////////////////////////////////////////////////////////////opacity
@mixin opacity($property, $value) {

  /* IE 8 */
  //-ms-filter: "progid:DXImageTransform.Microsoft.Alpha( #{$property} = $value)";
  /* IE 5-7 */
  filter: alpha(#{$property}= $value * 100 );
  /* Netscape */
  -moz-#{$property}: $value;
  /* Safari 1.x */
  -khtml-#{$property}: $value;
  /* Good browsers */
  #{$property}: $value;

}

//@include opacity(property, value);


//////////////////////////////////////////////////////////////////////////font-size


@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

//////////////////////////////////////////////////////////////////////////Gradient


@mixin linear-gradient($direction, $color-stops) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

//@include linear-gradient(hotpink, tomato);

////////////////////////////////////////////////////////////////////////////User Select

@mixin userSelect($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

//@include userSelect(none);

////////////////////////////////////////////////////////////////////////////select colors

@mixin selectColors($value2 ,$value) {
  &::-moz-selection { background: $value2}
  &::selection { background: $value2; }
  &::-moz-selection { color: $value}
  &::selection { color: $value; }

}
//@include selectColors($light_purpleo, $whiteo);


