//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// services Screen
//////////////////////////////////////////////////////////////////////////////////////////

.services-cover-mob{
  position: relative;
  height: 580px;
  background: #f1f5f7 url("../img/cover-services-mob.svg")  center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  .titleWizSlogan{
    z-index: 3;
    padding-top: 215px;
    .AnimateSmall{
      letter-spacing: 30px;
    }
  }
}



.services-mob{
  position: relative;
  padding-bottom: 40px;
  .services_block_text{
    margin-top: 50px;
    color: #3a3a3a;
    h2{
      @include selectColors($dark_purpleo, $whiteo);
    }
    p{
      @include selectColors($dark_purpleo, $whiteo);
    }
  }
  .imgMobileApp{
    @include userSelect(none);
    img{
      width: 100%;
      @media only screen and (min-width : 100px) {
        position: static;
        width: 100%;
        margin-top: -50px;

      }
      @media only screen and (min-width : 320px) {
        position: static;
        width: 100%;
        margin-top: -80px;

      }
      @media only screen and (min-width : 480px) {
        position: static;
        width: 100%;
        margin-top: -50px;

      }
      @media only screen and (min-width : 768px) {
        position: static;
        width: 100%;
        margin-top: 50px;

      }
      @media only screen and (min-width : 992px) {
        position: absolute;
        top: -115px;
      }
      @media only screen and (min-width : 1200px) {
        position: absolute;
        top: -206px;
      }
      @media only screen and  (min-width: 1450px) {
        position: absolute;
        top: -300px;
        width: 660px;
      }
    }
  }

  .servicesContent{
    h2{
      font-size: 30px;
      letter-spacing: -1px;
      margin-top: 50px;
    }
    p{
      font-size: 15px;

    }
    strong{
      font-size: 15px;

    }
    .Completed{
      ul{
        li{
          display: inline-block;
          a{
            width: 65px;
            height: 65px;
            display: block;
            border: 2px solid #f1f5f7;
            text-decoration: none;
            &:hover{
              border: 2px solid #d4d4d4;
              text-decoration: none;
            }
          }
          &:last-child a.blackChild {
            border: 2px solid #3a3a3a;
            @include vendor-prefixes(border-radius, 50%);
            padding: 19px 23px;
            color: #3a3a3a;
            text-decoration: none;

            &:hover{
              border: 2px solid #3a3a3a;
              text-decoration: none;
              background:#3a3a3a ;
              color: #ffffff;
            }
          }
          &:last-child a.whiteChild {
            border: 2px solid $whiteo;
            @include vendor-prefixes(border-radius, 50%);
            padding: 19px 23px;
            color: $whiteo;
            text-decoration: none;
            &:hover{
              background:$whiteo ;
              border: 2px solid $whiteo;
              text-decoration: none;
              color: #000000;
            }
          }
        }
      }
    }
  }

}

.requestIdeaMobile{
  margin-top: 75px;
}