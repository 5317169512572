//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// services Screen
//////////////////////////////////////////////////////////////////////////////////////////

.services-cover{
  height: 580px;
  background: #f1f5f7 url("../img/servicesCover.svg")  center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  .titleWizSloganContainer{
    .titleWizSlogan{
      padding-top: 200px;
    }
  }
  @media (max-width: 768px) {
    height: 580px;
  }
  @media (max-width: 480px) {
    height: 535px;
  }
}
.allServicesContain {
  margin-top: -195px;
  z-index: 4;
  position: relative;
}

//generall in all services
.servicesContent{
  h2{
    letter-spacing: -1px;
    margin-top: 80px;
    font-size: 35px;
    @include selectColors($dark_purpleo, $whiteo);

    @media (max-width: 533px) {
      margin-top: 28px;
      font-size: 28px;

    }
  }
  p{
    font-size: 15px;
    @include selectColors($light_purpleo, $whiteo);

  }
  strong{
    font-size: 15px;
    font-weight: 100;
    text-decoration: underline;
    @include userSelect(none);

  }
  .Completed{
    ul{
      @include userSelect(none);
      li{
        display: inline-block;
        margin: auto 1px;
        a{
          width: 55px;
          height: 55px;
          display: block;
          border: 1px solid #ffffff;
          text-decoration: none;
          opacity: 0.8;
          @include vendor-prefixes(transition, all 0.3s ease-in-out);
          &:hover{
            border: 1px solid #d4d4d4;
            text-decoration: none;
            opacity: 1;

          }
        }
        &:last-child a.blackChild {
          border: 2px solid #3a3a3a;
          @include vendor-prefixes(border-radius, 50%);
          padding: 13px 20px;
          color: #3a3a3a;
          text-decoration: none;

          &:hover{
            border: 2px solid #3a3a3a;
            text-decoration: none;
            background:#3a3a3a ;
            color: #ffffff;
            letter-spacing: 5px;
            padding: 13px 13px;
          }
        }
        &:last-child a.whiteChild {
          border: 2px solid $whiteo;
          @include vendor-prefixes(border-radius, 50%);
          padding: 13px 20px;
          color: $whiteo;
          text-decoration: none;
          &:hover{
            background:$whiteo ;
            border: 2px solid $whiteo;
            text-decoration: none;
            color: #000000;
            letter-spacing: 5px;
            padding: 13px 13px;
          }
        }
      }
    }
  }
}
.mobileAppservicesBg{
  position: relative;
  .mobileAppservicesIMG{
    img {
      position: absolute;
      right: 0;
      bottom: 9px;
      width: 650px;
      z-index: 3;
      @include userSelect(none);


      @media (max-width: 1200px) {
        right: 0;
        bottom: 90px;
        width: 365px;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: block;
        position: static;
        width: 115%;
        margin-right: -62px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
    }
  }

}
.websolutionservicesBg{
  h2{
    @include selectColors($dark_cyano, $whiteo);
  }
  p{
    @include selectColors($dark_cyano, $whiteo);
  }
  .websolutionservicesIMG{
    width: 100%;
    img{
      @include userSelect(none);
      width: 100%;
      @media (min-width: 992px) {
        margin-top: 45px;
      }
      @media (max-width: 991px) {
        margin-top: 130px;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: block;
        margin-top: 15px;

      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}

    }
  }
}
.gamesservices{
    h2{
      @include selectColors(#328c2e, $whiteo);
    }
    p{
      @include selectColors(#328c2e, $whiteo);
    }
  .gamesservicesIMG{
    img{
      @include userSelect(none);
      width: 100%;
      max-width: 420px;
      display: block;
      margin: auto;
      @media (min-width: 992px) {
        margin-top: 50px;
      }
      @media (max-width: 991px) {
        margin-top: 130px;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: block;
        margin-top: 15px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
    }
  }
}
.threeDservices{
  h2{
    @include selectColors(#4b4c4d, $whiteo);
  }
  p{
    @include selectColors(#4b4c4d, $whiteo);
  }
  .gthreeDservicesIMG{
    img{
      @include userSelect(none);
      width: 100%;
      max-width: 420px;
      display: block;
      margin: auto;
      @media (min-width: 992px) {
        margin-top: 50px;
      }
      @media (max-width: 991px) {
        margin-top: 130px;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: block;
        margin-top: 15px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
    }
  }
}
.multimediaservices{
  min-height: 381px;
  h2{
    @include selectColors(#FFD43E, $blacko);
  }
  p{
    @include selectColors(#FFD43E, $blacko);
  }
  @media (min-width: 992px){
    width: 50%;
    float: left;
    //min-height: 100vh;
    //max-height: 100vh;
    //overflow: hidden;
  }
  @media (max-width: 991px){
    width: 100%;
    float: none;
    min-height: 300px;
    max-height: 100%;
    overflow: hidden;
  }
  .multimediaservicesIMG{
    img{
      @include userSelect(none);
      margin: auto;
      display: none;
      @media (min-width: 992px) {
        width: 337px;
        display: none;
        margin-top: 50px;
      }
      @media (max-width: 991px) {
        margin-top: 140px;
        width: 100%;
        display: none;

      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: none;
        margin-top: 15px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
    }
  }
}
.erpservices{
  min-height: 381px;
  h2{
    @include selectColors(#cd2652, $whiteo);
  }
  p{
    @include selectColors(#cd2652, $whiteo);
  }
  //position: relative;
  @media (min-width: 992px){
    width: 50%;
    float: right;
    //min-height: 100vh;
    //max-height: 100vh;
    //overflow: hidden;
  }
  @media (max-width: 991px){
    width: 100%;
    float: none;
    min-height: 300px;
    max-height: 100%;
    overflow: hidden;
  }

  .erpservicesIMG{
    img{
      @include userSelect(none);
      max-width: 420px;
      display: none;
      margin: auto;
      @media (min-width: 992px) {
        width: 396px !important;
        margin-top: 50px;
        margin-left: -35px;
        width: 100%;
        display: none;


      }
      @media (max-width: 991px) {
        margin-top: 20px;
        display: none;

      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: none;
        margin-top: 15px;
        width: 100%;

      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
    }
  }
}
.uxservices{
  h2{
    @include selectColors(#08273c, $whiteo);
  }
  p{
    @include selectColors(#08273c, $whiteo);
  }
  .uxservicesIMG{
    img{
      @include userSelect(none);
      width: 100%;
      max-width: 420px;
      display: block;
      margin: auto;
      @media (min-width: 992px) {
        margin-top: 50px;
      }
      @media (max-width: 991px) {
        margin-top: 130px;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: block;
        margin-top: 15px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
    }
  }
}
.digitalmarketingservices{
  min-height: 381px;
  h2{
    @include selectColors(#f23b2f, $whiteo);
  }
  p{
    @include selectColors(#f23b2f, $whiteo);
  }
  .digitalmarketingservicesIMG{
    img{
      @include userSelect(none);
      width: 100%;
      max-width: 420px;
      display: block;
      margin: auto;
      @media (min-width: 992px) {
        margin-top: 50px;
      }
      @media (max-width: 991px) {
        margin-top: 130px;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: block;
        margin-top: 15px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
    }
  }
}
.traningservices{

  position: relative;

  .traningservicesIMG{
    @include userSelect(none);

    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    img{
      height: 100%;
      @include userSelect(none);

    }
  }

}
.vrservices{
  h2{
    @include selectColors(#936a62, $whiteo);
  }
  p{
    @include selectColors(#936a62, $whiteo);
  }
  .vrservicesIMG{
    img{
      display: block;
      margin: auto;
      @include userSelect(none);

      @media (min-width: 992px) {
        width: 100%;
      }
      @media (max-width: 991px) {
        margin-top: 130px;
        width: 100%;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: block;
        margin-top: 15px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
    }
  }
}
.interiorservices{
  h2{
    @include selectColors(#25adc6, $whiteo);
  }
  p{
    @include selectColors(#25adc6, $whiteo);
  }
  .interiorservicesIMG{
    img{
      @include userSelect(none);
      width: 100%;
      max-width: 700px;
      display: block;
      margin: auto;
      @media (min-width: 992px) {
        margin-top: 50px;
      }
      @media (max-width: 991px) {
        margin-top: 130px;
      }
      @media (max-width: 768px) {
        display: none;
      }
      @media (max-width: 533px) {
        display: block;
        margin-top: 15px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {display: block;  }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {display: block;}
    }
  }
}
.arservices{
  min-height: 381px;
  position: relative;
  .arservicesIMG{
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    img{
      height: 100%;
      @include userSelect(none);
    }
  }

}


//ServicesBG only
.mobileAppservicesBg{
  background: $whiteo url("../img/bg-slices.svg")  center center no-repeat;
  background-size: contain;
  @include vendor-prefixes(border-radius, 200px 0 0 0 );
  @media (max-width: 1200px) {
    @include vendor-prefixes(border-radius, 110px 0 0 0 );

  }
  @media (max-width: 678px) {
    @include vendor-prefixes(border-radius, 110px 110px 0 0 );

  }
  @media (max-width: 533px) {
    @include vendor-prefixes(border-radius, 50px 50px 0 0 );

  }
  @include vendor-prefixes(transition, all 0.6s ease-in-out);
  position: relative;

}
.websolutionservicesBg{
  background: $light_cyano;
  background: rgb(159, 85, 218);
  background: -moz-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(159, 85, 218)), color-stop(100%, rgb(40, 201, 229)));
  background: -webkit-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
  background: -o-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
  background: -ms-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
  background: linear-gradient(to right, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );

}
.gamesservicesBg{
  background: #8dd749;
  background: rgb(50, 140, 46);
  background: -moz-linear-gradient(left, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(50, 140, 46)), color-stop(100%, rgb(141, 215, 73)));
  background: -webkit-linear-gradient(left, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
  background: -o-linear-gradient(left, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
  background: -ms-linear-gradient(left, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
  background: linear-gradient(to right, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}
.threeDservicesBg{
  background: #4b4c4d;
  background: rgb(41, 41, 43);
  background: -moz-linear-gradient(left, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(41, 41, 43)), color-stop(100%, rgb(75, 76, 77)));
  background: -webkit-linear-gradient(left, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
  background: -o-linear-gradient(left, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
  background: -ms-linear-gradient(left, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
  background: linear-gradient(to right, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}
.multimediaservicesBg{
  background: #fff777;
  background: rgb(255, 212, 62);
  background: -moz-linear-gradient(left, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(255, 212, 62)), color-stop(100%, rgb(255, 247, 119)));
  background: -webkit-linear-gradient(left, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
  background: -o-linear-gradient(left, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
  background: -ms-linear-gradient(left, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
  background: linear-gradient(to right, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}
.erpservicesBg{
  background: #cd2652;
  background: rgb(157, 0, 19);
  background: -moz-linear-gradient(left, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(157, 0, 19)), color-stop(100%, rgb(205, 38, 82)));
  background: -webkit-linear-gradient(left, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
  background: -o-linear-gradient(left, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
  background: -ms-linear-gradient(left, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
  background: linear-gradient(to right, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}
.uxservicesBg{
  background: #08263b;
  background: rgb(8, 38, 59);
  background: -moz-linear-gradient(left, rgb(8, 38, 59) 0%, rgb(8, 56, 90) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(8, 38, 59)), color-stop(100%, rgb(8, 56, 90)));
  background: -webkit-linear-gradient(left, rgba(8, 38, 59, 0.73) 0%, rgb(8, 56, 90) 100%);
  background: -o-linear-gradient(left, rgb(8, 38, 59) 0%, rgb(8, 56, 90) 100%);
  background: -ms-linear-gradient(left, rgb(8, 38, 59) 0%, rgb(8, 56, 90) 100%);
  background: linear-gradient(to right, rgb(8, 38, 59) 0%, rgb(8, 56, 90) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}
.traningservicesBg{
  background: #d7e3ea;
  .Addbg{
    @media (max-width: 992px) {
      background: rgba(241, 248, 233, 0.68);
      @include vendor-prefixes(border-radius, 20px);
      z-index: 5;
    }
  }
}
.vrservicesBg{
  background: #6671b1 url("../img/vrservicesBg.png")  center center no-repeat;
  background-size: cover;
}
.digitalmarketingservicesBg{
  background: #ff4437;
  background: rgb(228, 50, 37);
  background: -moz-linear-gradient(left, rgb(228, 50, 37) 0%, rgb(255, 68, 55) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(228, 50, 37)), color-stop(100%, rgb(255, 68, 55)));
  background: -webkit-linear-gradient(left, rgb(228, 50, 37) 0%, rgb(255, 68, 55) 100%);
  background: -o-linear-gradient(left, rgb(228, 50, 37) 0%, rgb(255, 68, 55) 100%);
  background: -ms-linear-gradient(left, rgb(228, 50, 37) 0%, rgb(255, 68, 55) 100%);
  background: linear-gradient(to right, rgb(228, 50, 37) 0%, rgb(255, 68, 55) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}
.interiorservicesBg{
  background: #25adc6;
  background: rgb(110, 221, 177);
  background: -moz-linear-gradient(left, rgb(110, 221, 177) 0%, rgb(37, 173, 198) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(110, 221, 177)), color-stop(100%, rgb(37, 173, 198)));
  background: -webkit-linear-gradient(left, rgb(110, 221, 177) 0%, rgb(37, 173, 198) 100%);
  background: -o-linear-gradient(left, rgb(110, 221, 177) 0%, rgb(37, 173, 198) 100%);
  background: -ms-linear-gradient(left, rgb(110, 221, 177) 0%, rgb(37, 173, 198) 100%);
  background: linear-gradient(to right, rgb(110, 221, 177) 0%, rgb(37, 173, 198) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}
.arservicesBg{
  background: #f1f5f7;
  .Addbg{
    @media (max-width: 992px) {
      background: rgba(241, 248, 233, 0.68);
      @include vendor-prefixes(border-radius, 20px);
      z-index: 5;
    }
  }
}



//all service--first--cover
.whitCover{
  background: $whiteo url("../img/bg-slices.svg")  center center no-repeat;
  background-size: contain;
  @include vendor-prefixes(border-radius, 200px 0 0 0 );
  @media (max-width: 1200px) {
    @include vendor-prefixes(border-radius, 110px 0 0 0 );

  }
  @media (max-width: 678px) {
    @include vendor-prefixes(border-radius, 110px 110px 0 0 );

  }
  @media (max-width: 533px) {
    @include vendor-prefixes(border-radius, 50px 50px 0 0 );

  }
  @include vendor-prefixes(transition, all 0.6s ease-in-out);
  position: relative;

  .services_block_text{
    margin-top: 50px;
    color: #3a3a3a;
  }
}





















