//
// Progress animations
//

@keyframes progress-bar-stripes {
  from  { background-position: $spacer-y 0; }
  to    { background-position: 0 0; }
}


//
// Basic progress bar
//

.progress {
  display: block;
  width: 100%;
  height: $spacer-y;
  margin-bottom: $spacer-y;
}
.progress[value] {
  // IE10 uses `color` to set the bar background-color
  color: #0074d9;
  // Remove Firefox and Opera border
  border: 0;
  // Reset the default appearance
  appearance: none;
}
.progress[value]::-webkit-progress-bar {
  background-color: #eee;
  @include border-radius($border-radius);
  @include box-shadow(inset 0 .1rem .1rem rgba(0,0,0,.1));
}
.progress[value]::-webkit-progress-value::before {
  content: attr(value);
}
.progress[value]::-webkit-progress-value {
  background-color: #0074d9;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}
.progress[value="100"]::-webkit-progress-value {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

// Firefox styles must be entirely separate or it busts Webkit styles.
//
// Commented out for now because linter.
//
// $-moz-document url-prefix() {
//   .progress[value] {
//     background-color: #eee;
//     .border-radius($border-radius);
//     .box-shadow(inset 0 .1rem .1rem rgba(0,0,0,.1));
//   }
//   .progress[value]::-moz-progress-bar {
//     background-color: #0074d9;
//     border-top-left-radius: $border-radius;
//     border-bottom-left-radius: $border-radius;
//   }
//   .progress[value="0"]::-moz-progress-bar {
//     color: $gray-light;
//     min-width: 2rem;
//     background-color: transparent;
//     background-image: none;
//   }
//   .progress[value="100"]::-moz-progress-bar {
//     border-top-right-radius: $border-radius;
//     border-bottom-right-radius: $border-radius;
//   }
// }

// IE9 hacks to accompany custom markup. We don't need to scope this via media queries, but I feel better doing it anyway.
@media screen and (min-width:0\0) {
  .progress {
    background-color: #eee;
    @include border-radius($border-radius);
    @include box-shadow(inset 0 .1rem .1rem rgba(0,0,0,.1));
  }
  .progress-bar {
    display: inline-block;
    height: $spacer-y;
    text-indent: -999rem; // Simulate hiding of value as in native `<progress>`
    background-color: #0074d9;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
  .progress[width^="0"] {
    min-width: 2rem;
    color: $gray-light;
    background-color: transparent;
    background-image: none;
  }
  .progress[width="100%"] {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}


//
// Striped
//

.progress-striped[value]::-webkit-progress-value {
  @include gradient-striped();
  background-size: $spacer-y $spacer-y;
}
.progress-striped[value]::-moz-progress-bar {
  @include gradient-striped();
  background-size: $spacer-y $spacer-y;
}
// IE9
@media screen and (min-width:0\0) {
  .progress-bar-striped {
    @include gradient-striped();
    background-size: $spacer-y $spacer-y;
  }
}


//
// Animated
//

.progress-animated[value]::-webkit-progress-value {
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-animated[value]::-moz-progress-bar {
  animation: progress-bar-stripes 2s linear infinite;
}
// IE9
@media screen and (min-width:0\0) {
  .progress-animated .progress-bar-striped {
    animation: progress-bar-stripes 2s linear infinite;
  }
}


//
// Variations
//

.progress-success {
  @include progress-variant($progress-bar-success-bg);
}
.progress-info {
  @include progress-variant($progress-bar-info-bg);
}
.progress-warning {
  @include progress-variant($progress-bar-warning-bg);
}
.progress-danger {
  @include progress-variant($progress-bar-danger-bg);
}
