/*about Page*/
.partnersPage-cover {
  position: relative;
  height: 580px;
  background: url("../img/cover-partner.svg") top center no-repeat;
  background-size: contain;
  @media (max-width: 1300px) {
    background-size: cover;
  }

  @media (max-width: 768px) {
    height: 580px;
  }
  @media (max-width: 480px) {
    height: 630px;
  }

  .titleWizSloganContainer {
    z-index: 3;
    .titleWizSlogan {
      h1 {

      }
      p{

      }
    }
  }
  .friends {
    width: 387px;
    z-index: 2;
    margin: auto;
    margin-top: 115px;
    @media (max-width: 533px) {
      width: 100%;
      margin-top: 20px;
    }
    @media (min-width: 1450px) {
      margin-top: 155px;
    }
    text-align: center;
    display: block;
    .partnersColor0 {
      fill: none;
    }
    .partnersColor1 {
      fill: #E5C6AC;
    }
    .partnersColor2 {
      fill: #F0D0B3;
    }
    .partnersColor3 {
      fill: #28D4F1;
    }
    .partnersColor4 {
      fill: #28C6C6;
    }
    .partnersColor5 {
      fill: #13A1A8;
    }
    .partnersColor6 {
      fill: #FFFFFF;
    }
    .partnersColor7 {
      fill: #E3B59D;
    }
    .partnersColor8 {
      fill: #D38985;
    }
    .partnersColor9 {
      fill: none;
      stroke: #CDA18B;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .partnersColor10 {
      fill: #DD9B90;
    }
    .partnersColor11 {
      fill: #5A4A42;
    }
    .partnersColor12 {
      fill: #906D5A;
    }
    .partnersColor13 {
      fill: #DB9B93;
    }
    .partnersColor14 {
      fill: #46282B;
    }
    .partnersColor15 {
      fill: #CDA18B;
    }
    .partnersColor16 {
      fill: #E8808E;
    }
    .partnersColor17 {
      fill: #643D42;
    }
    .partnersColor18 {
      fill: #DACBFF;
    }
    .partnersColor19 {
      fill: #9784D3;
    }
    .partnersColor20 {
      fill: #675B99;
    }
    .partnersColor21 {
      fill: #0C0C0C;
    }
    .partnersColor22 {
      fill: #DC9A8F;
    }
    .partnersColor23 {
      fill: #493125;
    }
    .partnersColor24 {
      fill: #DEAA96;
    }
    .partnersColor25 {
      opacity: 0.34;
      fill: #26A9E0;
    }
    .partnersColor26 {
      fill: #493C35;
    }
    .partnersColor27 {
      fill: #303030;
    }
    .partnersColor28 {
      fill: #0F0F0F;
    }
    .partnersColor29 {
      fill: #E5C6AC;
      stroke: url(#Unnamed_Pattern);
      stroke-miterlimit: 10;
    }
    .partnersColor30 {
      fill: #E5C6AC;
      stroke: url(#SVGID_1_);
      stroke-miterlimit: 10;
    }
    .partnersColor31 {
      fill: #FCFCFC;
    }
  }
}

/*All solutions */
.partnersbody {
  background: url("../img/partners-all-bg.png") center bottom no-repeat;
  background-size: contain;
  @include userSelect(none);
  .allpartners {
    a{
      height: 150px;
      min-height: 150px;
      width: 100%;
      margin: 15px auto;
      border: 1px solid $whiteo;
      display: table;
      @include vendor-prefixes(border-radius,15px);
      @include vendor-prefixes(transition, all 0.9s ease-in-out);
      background-color: $whiteo;
      @include vendor-prefixes(filter, grayscale(1)brightness(1.01));
      @include opacity(opacity, 0.9);

      &:hover{
        border: 1px solid #ced2d4;
        @include vendor-prefixes(filter, grayscale(0.5)brightness(1));
      }

      span{
        display: table-cell;
        vertical-align: middle;
        img {
          width: 85%;
          text-align: center;
          margin: auto;
          display: block;
        }
      }
      &.btn_more_b{
        border:2px solid $dark_purpleo;
        padding: 1px 10px;
        color: $dark_purpleo;
        @include vendor-prefixes(border-radius, 200px);
        @include vendor-prefixes(transition, all 0.6s ease-in-out);
        font-size: 18px;
        text-decoration:none;
        display: block;
        max-width: 85px;
        min-width: 90px;
        margin: 15px auto;
        @include userSelect(none);
        height: auto;
        min-height: 0;

        &:hover{
          background: $dark_purpleo;
          letter-spacing: 10px;
          color: $whiteo !important;
          text-indent: 10px;
        }
      }
    }
  }
}