///***********************************//
///************ Index Page ///********//
///**********************************//

.home-cover{
  position: relative;
  //min-height: 92vh;
  background: #ffffff url("../img/cover-home.svg")  center center no-repeat;
  background-size: cover;
  &:hover{
    .plyIntroVideo {
      //@include vendor-prefixes(transform, rotate(-5deg));
      //@include vendor-prefixes(transition, all 1s ease-in-out);
    }
  }
}
/*if browser not support*/
.no-svg .home-cover {
  background: #ffffff url("../img/cover-home.png")  center center no-repeat;
}



//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// play Video button
//////////////////////////////////////////////////////////////////////////////////////////

.plyIntroVideo {
  background: url("../img/video_intro_button.svg")  center center no-repeat;
  width: 300px;
  height: 300px;
  position: relative;
  margin: 290px auto auto auto;
  z-index: 3;
  @media (max-width: 768px) {
    margin: 50px auto auto auto;
    width: 250px;
    height: 250px;
  }
  @media (min-width: 1450px) {
    margin-top: 375px;
  }

  /* ----------- iPad  ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {margin: 135px auto auto auto;}
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {margin: 135px auto auto auto;}
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {margin: 135px auto auto auto;}
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {margin: 135px auto auto auto;}
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {margin: 135px auto auto auto;}
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {margin: 135px auto auto auto;}
  &:hover{
    //@include vendor-prefixes(transform, rotate(-5deg));
    //@include vendor-prefixes(transition, all 1s ease-in-out);
  }
  a{
    background: $dark_purpleo;
    position: absolute;
    top: 98px;
    left: 98px;
    padding: 13px 13px;
    @include vendor-prefixes(border-radius, 50%);

    width: 75px;
    height: 75px;
    @media (max-width: 768px) {
      top: 85px;
      left: 84px;
      padding: 10px;
      width: 60px;
      height: 60px;
    }

    &:hover{
      background: $light_cyano;
      @include vendor-prefixes(transform, rotateZ(360deg));
      @include vendor-prefixes(transition, all 0.6s ease-in-out);
    }

    i{
      color: $whiteo;
      font-size: 50px;
      @media (max-width: 768px) {
        font-size: 40px;

      }
    }
  }
}
/*if browser not support*/
.no-svg .plyIntroVideo {
  background: url("../img/video_intro_button.png")  center center no-repeat;
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// AdboutHome
//////////////////////////////////////////////////////////////////////////////////////////
.aboutHome{
  background:  url("../img/bg-slices.svg") left center no-repeat , url("../img/aboutbottomRight.svg") right bottom no-repeat ;
  background-size: contain, 65%;
  background-color: $whiteo  ;
  padding-top: 103px;
  padding-bottom: 103px;

  img{
    width: 100%;
    max-width: 375px;
    text-align: right;
    @include userSelect(none);

    @media (max-width: 768px) {
      margin-top: 150px;
    }
    @media (max-width: 544px) {
      //margin-top: 150px;
    }
  }
}
.textBlock{
  h3{
    font-size: 35px;
    font-weight: bolder;
    letter-spacing: -1px;;
    color: #6a6a6a;
    @include selectColors($light_purpleo, $whiteo);
  }
  p{
    font-size: 15px;
    color: #6a6a6a;
    @include selectColors($light_purpleo, $whiteo);
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Services Home
//////////////////////////////////////////////////////////////////////////////////////////
.ServicesHome{
  background:  url("../img/servicestopLeft.svg") left top no-repeat , url("../img/servicesBottomCenter.svg") center bottom no-repeat ;
  background-size:  35% , contain;
  background-color: #f1f5f7  ;
  padding-top: 50px;
  padding-bottom: 103px;
  margin-bottom: -3px;
  .col-lg-3{
    padding: 0 !important;
  }
  .row.borderRow{
    border: rgba(255, 255, 255, 0.28) solid 1px;

  }
  .oneservice{
    border: rgba(255, 255, 255, 0.28) solid 1px;
    padding: 20px;
    min-height: 385px;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
      min-height: 455px;
    }
    @media screen and (min-width: 1200px) {
      min-height: 385px;
    }
    a{
      text-decoration: none;
      img{
        max-width: 160px;
        margin-bottom: 35px;
        @include userSelect(none);
        &:hover{
          @include vendor-prefixes(transform, scale(1.05,1.05));
          @include vendor-prefixes(transition, all 0.6s ease-in-out);
        }
      }
      h3{
        color: $blacko;
        font-weight: bold;
        letter-spacing: 0px;
        font-size: 22px;
        @include selectColors($light_purpleo, $whiteo);
        @include vendor-prefixes(transition, all 0.5s linear);

      }
    }
    p{
      color: #716b78;
      font-size: 16px;
      @include selectColors($light_purpleo, $whiteo);
    }
    &:hover h3 {
      color: $dark_purpleo;

    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Why Us Home
//////////////////////////////////////////////////////////////////////////////////////////
.whyHome{
  background:  url("../img/solutionBg.png") center center no-repeat  ;
  background-size: contain;
  background-color: #26b6cf  ;
  padding-top: 50px;
  padding-bottom: 50px;
  p{
    @include selectColors($light_cyano, $whiteo);
  }
  .oneWhy{
    background: rgba(255,255,255,0.50);
    @include vendor-prefixes(box-shadow, 1px 1px 9px 0px rgba(0,0,0,0.1));
    position: relative;
    min-height: 72px;
    margin: 10px auto;
    max-width: 300px;
    @include userSelect(none);
    h5{
      //font-weight: bold;
      display: inline-block;
      font-size: 18px;
      position: absolute;
      color: #3d4344;
    }
    span{
      width: 50px;
      height: 50px;
      display: inline-block;
      padding: 8px;
      position: absolute;
      border: 1px solid $whiteo;
      i{
        font-size: 32px;
      }
    }
    .iconRight{
      h5{
        position: absolute;
        left: 28px;
        top: 29px;
      }
      span{
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }
    .iconleft {
      h5{
        position: absolute;
        right: 28px;
        top: 29px;
      }
      span{
        position: absolute;
        left: 15px;
        top: 10px;
      }
    }
    &.active{
      background: rgba(255,255,255,1);
      span{
        border: 1px solid $dark_cyano;
      }
    }
    &:hover{
      background: rgba(255,255,255,1);
      span{
        border: 1px solid $dark_cyano;
      }
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// solution Home
//////////////////////////////////////////////////////////////////////////////////////////
.solutionHome{
  background:  url("../img/solutionTopCenter.svg") center top no-repeat , url("../img/solutionWaterMark.png") center bottom no-repeat ;
  background-size: contain , contain;
  background-color: #f1f5f7  ;
  padding-top: 80px;
  padding-bottom: 0px;
  margin-bottom: 70px;
  margin-top: -3px;

  img.img-responsive{
    margin-bottom: 20px;

  }
}
.masonryWrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

}
.grid {
  float: none;
  @include vendor-prefixes(transition, all 0.6s ease-in-out);
  opacity: 1;
  &:hover{
    //@include vendor-prefixes(box-shadow, 0 0 13px 2px rgba(0, 0, 0, 0.12));
    opacity: 1;

  }
  @media screen and (min-width: 1200px) {
    //float: right;
    width: 25%;
  }
  .texT-Block-mason {
    padding-bottom: 8px;

    .img-mason {
      width: 100%;
      height: 100%;
      min-height: 100%;
      //background: $light_cyano
    }

  }
}
.grid.col-lg-3.col-md-4.col-sm-6.col-xs-6{
  padding-bottom: 20px;
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// partner
//////////////////////////////////////////////////////////////////////////////////////////
.partnerHome {
  background:  url("../img/bg-slices.svg") right center no-repeat  ;
  padding-bottom: 20px;
  .owl-item{
    margin: auto 2px;
  }
  .item{
    @include userSelect(none);
    padding: 15px;
    img{
      width: 100%;
    }

    margin: 15px auto;
    border: 1px solid $whiteo;
    @include vendor-prefixes(border-radius,15px);
    @include vendor-prefixes(transition, all 0.9s ease-in-out);
    background-color: $whiteo;
    @include vendor-prefixes(filter, grayscale(1)brightness(1.01));
    @include opacity(opacity, 0.9);

    &:hover{
      border: 1px solid #ced2d4;
      @include vendor-prefixes(filter, grayscale(0.5)brightness(1));
    }
  }
}



