//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// services Screen
//////////////////////////////////////////////////////////////////////////////////////////

.Portfolio-cover{
  height: 580px;
  background: #f1f5f7 url("../img/cover-services-web.svg")  center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  .titleWizSlogan{
    padding-top: 215px;
    .AnimateSmall{
      letter-spacing: 30px;
    }
  }

  @media (max-width: 768px) {
    height: 580px;
  }

  @media (max-width: 480px) {
    height: 500px;
  }
}
.portfolio_container {
  margin-top: -200px;
  .portfolioCover{
    background: $light_grayo  no-repeat;
    background-size: contain;
    @include vendor-prefixes(border-radius, 200px 200px 0 0 );
    @media (max-width: 1200px) {
      @include vendor-prefixes(border-radius, 110px 110px 0 0 );

    }
    @media (max-width: 678px) {
      @include vendor-prefixes(border-radius, 110px 110px 0 0 );

    }
    @media (max-width: 533px) {
      @include vendor-prefixes(border-radius, 50px 50px 0 0 );

    }
    @include vendor-prefixes(transition, all 0.6s ease-in-out);
    position: relative;

  }

  .portfolio{
    position: relative;
    #portfolioSlider{
      background:  url("../img/bg-slices.svg") left center no-repeat  ;
      margin-bottom: 10px;
      .item{
        padding: 15px;
        @include userSelect(none);
        img{
          width: 100%;
          @include userSelect(none);
          cursor: pointer;
        }
        span{
          width: 0px;
          height: 0px;
          @include vendor-prefixes(border-radius, 50%);
          background: #ffffff;
          display: block;
          margin: 10px auto;
          @include vendor-prefixes(transition, all 0.2s ease-in-out);

        }
        .mixitup-control-active + span {
          width: 5px;
          height: 5px;
          background: #757575;
          border-radius: 23px;
          display: block;

        }
      }

    }
    .worksDone{
      background-color: #f1f5f7;
      padding-top: 15px;
      .col-lg-3 ,.col-md-4 , .col-sm-6{
        padding-left: 5px;
        padding-right: 5px;
      }

      .shoot{
        width: 100%;
        height: 300px;
        overflow: hidden;
        background-color: #f9f9f9;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        z-index: 2;
        position: relative;
        @include vendor-prefixes(border-radius, 17px 6px);
        label{
          background-color: #3d3d3d;
          padding: 2px 10px 0 10px;
          @include vendor-prefixes(border-radius, 12px 3px );
          color: #fff;
          text-align: center;
          position: absolute;
          left: 10px;
          top: 9px;
          min-width: 47px;
        }
        i{
          position: absolute;
          left: 47%;
          top: 42%;
          padding: 10px;
          @include vendor-prefixes(border-radius, 50% );
          background: rgba(241, 248, 233, 0.54);
          color: #000000;
        }
        opacity: 0.8;
        -webkit-filter: contrast(1.2);
        filter: contrast(1.2);
        @include vendor-prefixes(transition, all 0.2s ease-in-out);

        &:hover{
          opacity: 1;
          -webkit-filter: contrast(1);
          filter: contrast(1);
          //animation: moveUpDownBg 3s infinite ease-in-out;
          @include vendor-prefixes(box-shadow, 0px 0px 8px 1px rgba(0, 0, 0, 0.25));
          @include vendor-prefixes(transition, all 1s ease-in-out);

        }
      }
      .threeD{
        label{
          background: #4b4c4d;
          background: rgb(41, 41, 43);
          background: -moz-linear-gradient(left, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgb(41, 41, 43)), color-stop(100%, rgb(75, 76, 77)));
          background: -webkit-linear-gradient(left, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
          background: -o-linear-gradient(left, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
          background: -ms-linear-gradient(left, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
          background: linear-gradient(to right, rgb(41, 41, 43) 0%, rgb(75, 76, 77) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );        }


      }
      .ar{
        label{
          background: #f1f5f7;
          color: #000000;

        }
      }
      .erp{
        label{
          background: #cd2652;
          background: rgb(157, 0, 19);
          background: -moz-linear-gradient(left, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgb(157, 0, 19)), color-stop(100%, rgb(205, 38, 82)));
          background: -webkit-linear-gradient(left, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
          background: -o-linear-gradient(left, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
          background: -ms-linear-gradient(left, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
          background: linear-gradient(to right, rgb(157, 0, 19) 0%, rgb(205, 38, 82) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
        }
      }
      .games{
        label{
          background: #8dd749;
          background: rgb(50, 140, 46);
          background: -moz-linear-gradient(left, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgb(50, 140, 46)), color-stop(100%, rgb(141, 215, 73)));
          background: -webkit-linear-gradient(left, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
          background: -o-linear-gradient(left, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
          background: -ms-linear-gradient(left, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
          background: linear-gradient(to right, rgb(50, 140, 46) 0%, rgb(141, 215, 73) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );        }
      }
      .trainingandcourses{
        label{
          background: #d7e3ea;
          color: #000000;
        }
      }
      .mobileapp{
        label{
          color: #000000;
          background-color: #ffffff;
        }
      }
      .vr{
        label{
          color: #ffffff;
          background: #b06a5e;
          background: rgb(159, 85, 218);
          background: -moz-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(176, 106, 94) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgb(159, 85, 218)), color-stop(100%, rgb(176, 106, 94)));
          background: -webkit-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(176, 106, 94) 100%);
          background: -o-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(176, 106, 94) 100%);
          background: -ms-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(176, 106, 94) 100%);
          background: linear-gradient(to right, rgb(159, 85, 218) 0%, rgb(176, 106, 94) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );

        }
      }
      .web{
        label{
          background: $light_cyano;
          background: rgb(159, 85, 218);
          background: -moz-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgb(159, 85, 218)), color-stop(100%, rgb(40, 201, 229)));
          background: -webkit-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
          background: -o-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
          background: -ms-linear-gradient(left, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
          background: linear-gradient(to right, rgb(159, 85, 218) 0%, rgb(40, 201, 229) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );

        }
      }
      .multimedia{
        label{
          color: #000000;

          background: #fff777;
          background: rgb(255, 212, 62);
          background: -moz-linear-gradient(left, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgb(255, 212, 62)), color-stop(100%, rgb(255, 247, 119)));
          background: -webkit-linear-gradient(left, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
          background: -o-linear-gradient(left, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
          background: -ms-linear-gradient(left, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
          background: linear-gradient(to right, rgb(255, 212, 62) 0%, rgb(255, 247, 119) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
        }
      }
      .branding{
        label{
          background-color: #f1f8e9;
          color: #000000;
        }
      }

    }
  }
}
@keyframes moveUpDownBg {
  0%{
    background-position: center center;
  }

  25%{
    background-position: top center;
  }
  50%{
    background-position: center center;
  }
  75%{
    background-position: bottom center;
  }
  100%{
    background-position: center center;
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Portfolio Details
//////////////////////////////////////////////////////////////////////////////////////////
.projectContainer{
  .ifImage{
    //max-width: 1400px;
    margin: auto;
    display: block;
    @include userSelect(none);
  }
  .fullWidth{
    width: 100% !important;
    @include userSelect(none);
  }
  .ifVideo{
    padding-top:50px;
    padding-bottom:50px;
    @include userSelect(none);
    iframe{
      max-height: 380px;
      @include userSelect(none);

    }
  }

}
.online{
  padding-top:20px;
  padding-bottom:20px;
  text-align: center;
  min-height: 100px;
  a{
    background: $light_grayo;
    padding: 10px 19px;
    margin: auto 5px;
    @include vendor-prefixes(border-radius,50px);
    @include vendor-prefixes(transition, all 0.6s ease-in-out);
    border: 2px solid $dark_purpleo;
    color: #141416;
    display: inline-block;
    min-width: 140px;
    @include userSelect(none);

    i{
      padding: 0px 0px 5px 8px;
      font-size: 15px;
      line-height: 2px;
    }
    &:hover{
      background: #141416;
      border: 2px $dark_purpleo solid;
      color: $light-grayo;
    }
  }
}
.shapproject{
  padding-top: 0;
  display: block;
  text-align: center;
  margin: auto;
  svg#shap_end{
    width: 101% !important;
    display: block;
    text-align: center;
    margin: auto;
    @media (max-width: 580px) {
      width: 120% !important;

    }
  }
}
.shareproject{
  background:$light-grayo ;
  margin: 30px auto;
  text-align: center;
  p{
    display: block;
    margin: auto;
    margin-bottom: 15px;
    @include selectColors($light_purpleo, $whiteo);

  }
  a{
    border: 2px #4a4a4a  solid;
    background: $light-grayo;
    @include vendor-prefixes(border-radius,50% );
    width: 55px;
    height: 55px;
    display: inline-block;
    padding: 14px 15px;
    color: #4a4a4a ;
    @include vendor-prefixes(transition, all 0.6s ease-in-out);

    i{
      font-size: 22px;
      margin: 0;
    }
    &:hover{
      background: $light_purpleo;
      border: 2px $dark_purpleo solid;
      color: $light-grayo;

    }
  }


}