// VARIABLES Custom

$radiusAlhabib_1 : 5px;
$radiusAlhabib_2 : 10px;
$radiusAlhabib_3 : 15px; /*default radius*/
$radiusAlhabib_4 : 20px;

.radiusAlhabib_1 {  border-radius: $radiusAlhabib_1}
.radiusAlhabib_2 {  border-radius: $radiusAlhabib_2}
.radiusAlhabib_3 {  border-radius: $radiusAlhabib_3}
.radiusAlhabib_4 {  border-radius: $radiusAlhabib_4}

$marginAlhabib_top_1 : 5px;
$marginAlhabib_top_2 : 10px;
$marginAlhabib_top_3 : 15px; /*default radius*/
$marginAlhabib_top_4 : 20px;
$marginAlhabib_top_5 : 30px;

.marginAlhabib_top_1 {  margin-top: $marginAlhabib_top_1}
.marginAlhabib_top_2 {  margin-top: $marginAlhabib_top_2}
.marginAlhabib_top_3 {  margin-top: $marginAlhabib_top_3}
.marginAlhabib_top_4 {  margin-top: $marginAlhabib_top_4}
.marginAlhabib_top_5 {  margin-top: $marginAlhabib_top_5}

$marginAlhabib_bottom_1 : 5px;
$marginAlhabib_bottom_2 : 10px;
$marginAlhabib_bottom_3 : 15px; /*default radius*/
$marginAlhabib_bottom_4 : 20px;
$marginAlhabib_bottom_5 : 30px;

.marginAlhabib_bottom_1 {  margin-bottom: $marginAlhabib_bottom_1}
.marginAlhabib_bottom_2 {  margin-bottom: $marginAlhabib_bottom_2}
.marginAlhabib_bottom_3 {  margin-bottom: $marginAlhabib_bottom_3}
.marginAlhabib_bottom_4 {  margin-bottom: $marginAlhabib_bottom_4}
.marginAlhabib_bottom_5 {  margin-bottom: $marginAlhabib_bottom_5}

//
//.overFLowHidden {
//  overflow: hidden;
//}

$paddingAlhabib_1 : 5px;
$paddingAlhabib_2 : 10px;
$paddingAlhabib_3 : 15px; /*default radius*/
$paddingAlhabib_4 : 20px;
$paddingAlhabib_5 : 30px;

.paddingAlhabib_1 {  padding: $paddingAlhabib_1}
.paddingAlhabib_2 {  padding: $paddingAlhabib_2}
.paddingAlhabib_3 {  padding: $paddingAlhabib_3}
.paddingAlhabib_4 {  padding: $paddingAlhabib_4}
.paddingAlhabib_5 {  padding: $paddingAlhabib_5}
