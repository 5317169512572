///***********************************//
///************ about Page ///********//
///**********************************//
.aboutPage-cover {
    height: 680px;
    @media (max-width: 768px) {
        height: 580px;
    }
    @media (max-width: 480px) {
        height: 500px;
    }
    background: url("../img/cover-about-page.svg") center center no-repeat;
    background-size: cover;
    .titleWizSlogan {
        padding-top: 230px;
        @media (max-width: 544px) {
            padding-top: 175px;
        }
    }
    p {
        color: $whiteo;
    }
}


/*video Player About*/

.videoPlayerAbout {
    @include userSelect(none);
    background: $whiteo url("../img/bg-slices.svg") center center no-repeat;
    margin-top: -110px;
    padding-top: 60px;
    padding-bottom: 60px;
    @include vendor-prefixes(border-radius,
    2px 100px 2px 2px);
    @media (max-width: 768px) {
        margin-top: -150px;
        padding-top: 0;
        padding-bottom: 0;
        border: #fff solid 5px;
        border-radius: 5px 5px 0 0;
    }
    @media (max-width: 544px) {
        margin-top: -120px;
        padding-top: 0;
        padding-bottom: 0;
        border: #fff solid 5px;
        border-radius: 5px 5px 0 0;
    }
    .embed-responsive-16by9 {
        padding-bottom: 36.25%;
        @media (max-width: 768px) {
            padding-bottom: 60.25%;
        }
        @media (max-width: 544px) {
            padding-bottom: 70.25%;
        }
    }
}


/*Mission vision Slider*/

#missionVisionSlider {
    @include selectColors($light_purpleo,
    $whiteo);
    .owl-item:nth-child(1) .item {
        background: $whiteo url("../img/missionVisionSlider-1.svg") center center no-repeat;
        background-size: cover;
    }
    .owl-item:nth-child(2) .item {
        background: $whiteo url("../img/missionVisionSlider-2.svg") center center no-repeat;
        background-size: cover;
    }
    .item {
        padding: 15px;
        min-height: 160px;
        margin-top: 35px;
        p {
            @include selectColors($light_purpleo,
            $whiteo);
        }
        h3 {
            @include selectColors($dark_purpleo,
            $whiteo);
        }
    }
    .owl-controls.clickable {
        background: $whiteo;
        margin-top: 0px;
        .owl-page {
            span {
                background: $light_purpleo;
                width: 2px;
                height: 2px;
                margin: 5px 3px;
            }
            &.active {
                span {
                    width: 6px;
                    height: 2px;
                    margin: 5px 3px;
                }
            }
        }
    }
}


/*Heros Slider*/

.HerosAbout {
    margin-top: 40px;
    //margin-bottom: 40px;
    background: url("../img/bg-slices.svg") right center no-repeat;
    background-position: center 0;
    @include vendor-prefixes(transition,
    all 3s ease-in-out);
    #HerosAbout {
        .item {
            max-height: 520px;
            position: relative;
            @include userSelect(none);
            img {
                width: 100%;
                @include userSelect(none);
            }
            .data {
                position: absolute;
                bottom: 5px;
                right: 0;
                left: 0;
                h2 {
                    color: $whiteo;
                    font-size: 25px;
                    margin-bottom: -5px;
                    text-transform: capitalize;
                }
                small {
                    color: $whiteo;
                    margin-bottom: -5px;
                }
                ul {
                    display: block;
                    li {
                        display: inline-block;
                        margin: auto 1px;
                        background: transparent;
                        padding: 6px 2px 0px 2px;
                        @include vendor-prefixes(border-radius,
                        50%);
                        @include vendor-prefixes(transition,
                        all 0.6s ease-in-out);
                        width: 30px;
                        height: 30px;
                        a {
                            color: $whiteo;
                            width: 29px;
                            height: 29px;
                            background: transparent;
                            border-radius: 50%;
                            padding: 5px;
                            display: inline-block;
                            span {
                                font-size: 15px;
                            }
                        }
                        &:hover {
                            background: $light_purpleo;
                            @include vendor-prefixes(transition,
                            all 0.6s ease-in-out);
                            width: 29px;
                            height: 29px;
                            background: rgba(0, 0, 0, 0);
                            border-radius: 50%;
                            padding: 5px;
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .owl-controls {
            margin-top: 0;
            text-align: center;
            background: #21172a;
            .owl-page {
                span {
                    width: 2px;
                    height: 2px;
                    margin: 5px 3px;
                }
                &.active {
                    span {
                        width: 6px;
                        height: 2px;
                        margin: 5px 3px;
                    }
                }
            }
        }
    }
}


/*why us*/

.whyAbout {
    background: url("../img/whyabout.png") top left no-repeat;
    background-size: contain;
    padding-top: 90px;
    padding-bottom: 90px;
    position: relative;
    img.rocket {
        position: absolute;
        right: -52px;
        bottom: -50px;
        width: 103px;
        @include vendor-prefixes(transform,
        rotate(-40deg));
        @include userSelect(none);
    }
    p {
        @include selectColors($light_purpleo,
        $whiteo);
    }
    .oneWhy {
        background: rgba(255, 255, 255, 0.50);
        @include vendor-prefixes(box-shadow,
        1px 1px 9px 0px rgba(0,
        0,
        0,
        0.1));
        position: relative;
        min-height: 72px;
        margin: 10px auto;
        max-width: 300px;
        @include userSelect(none);
        h5 {
            //font-weight: bold;
            display: inline-block;
            font-size: 18px;
            position: absolute;
            color: #3d4344;
            @include userSelect(none);
        }
        span {
            width: 50px;
            height: 50px;
            display: inline-block;
            padding: 8px;
            position: absolute;
            border: 1px solid $whiteo;
            i {
                font-size: 32px;
            }
        }
        .iconRight {
            h5 {
                position: absolute;
                left: 28px;
                top: 29px;
            }
            span {
                position: absolute;
                right: 15px;
                top: 10px;
            }
        }
        .iconleft {
            h5 {
                position: absolute;
                right: 28px;
                top: 29px;
            }
            span {
                position: absolute;
                left: 15px;
                top: 10px;
            }
        }
        &.active {
            background: rgba(255, 255, 255, 1);
            span {
                border: 1px solid $dark_cyano;
            }
        }
    }
}


/*testimonials*/

.testimonials {
    margin-top: 30px;
    margin-bottom: 30px;
    .testimonialstop {
        background: #f1f5f7 url("../img/requestBgTop.svg") center bottom no-repeat;
        background-size: 105%;
        min-height: 40px;
    }
    .testimonialsContent {
        background: url(../img/anotherservices-left.svg) center left no-repeat, $whiteo url(../img/requestBgBottom.svg) bottom center no-repeat;
        background-size: 7%, 103%;
        min-height: 365px;
        padding-top: 64px;
        padding-bottom: 65px;
        #testimonialsSlides {
            .item {
                p {
                    @include selectColors($light_purpleo,
                    $whiteo);
                }
                h4 {
                    @include selectColors($dark_purpleo,
                    $whiteo);
                }
                .AuthorImg {
                    width: 95px;
                    height: 95px;
                    display: block;
                    margin: auto;
                    @include vendor-prefixes(border-radius,
                    50%);
                }
            }
            .owl-page {
                span {
                    width: 2px;
                    height: 2px;
                    margin: 5px 3px;
                }
                &.active {
                    span {
                        width: 6px;
                        height: 2px;
                        margin: 5px 3px;
                    }
                }
            }
        }
    }
}


/*support Center*/

.supportCenter {
    position: relative;
    background: url("../img/support.png") center right no-repeat;
    background-size: contain;
    min-height: 275px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 85px;
    .startSupport {
        background: rgba(255, 255, 255, 0.50);
        @include vendor-prefixes(box-shadow,
        1px 1px 9px 0px rgba(0,
        0,
        0,
        0.1));
        position: relative;
        min-height: 72px;
        margin: 10px auto;
        max-width: 300px;
        h5 {
            //font-weight: bold;
            display: inline-block;
            font-size: 18px;
            position: absolute;
            color: #3d4344;
            @include userSelect(none);
        }
        span {
            width: 50px;
            height: 50px;
            display: inline-block;
            padding: 8px;
            position: absolute;
            border: 1px solid $whiteo;
            i {
                font-size: 32px;
            }
        }
        .iconRight {
            h5 {
                position: absolute;
                left: 28px;
                top: 29px;
            }
            span {
                position: absolute;
                right: 15px;
                top: 10px;
            }
        }
        .iconleft {
            h5 {
                position: absolute;
                right: 28px;
                top: 29px;
            }
            span {
                position: absolute;
                left: 15px;
                top: 10px;
            }
        }
    }
    .startSupport.active {
        background: rgba(255, 255, 255, 0.1);
        span {
            border: 1px solid $dark_cyano;
        }
    }
    span.dividerBar {
        @include vendor-prefixes(border-radius,
        50px);
        min-height: 8px;
        display: block;
        margin: 50px auto;
        width: 50%;
        @media (max-width: 768px) {
            width: 90%;
        }
        background: #9460d0;
        background: rgb(148,
        96,
        208);
        background: -moz-linear-gradient(left,
        rgb(148,
        96,
        208) 0%,
        rgba(50,
        127,
        182,
        1) 100%);
        background: -webkit-gradient(left top,
        right top,
        color-stop(0%,
        rgb(148,
        96,
        208)),
        color-stop(100%,
        rgba(50,
        127,
        182,
        1)));
        background: -webkit-linear-gradient(left,
        rgb(148,
        96,
        208) 0%,
        rgba(50,
        127,
        182,
        1) 100%);
        background: -o-linear-gradient(left,
        rgb(148,
        96,
        208) 0%,
        rgba(50,
        127,
        182,
        1) 100%);
        background: -ms-linear-gradient(left,
        rgb(148,
        96,
        208) 0%,
        rgba(50,
        127,
        182,
        1) 100%);
        background: linear-gradient(to right,
        rgb(148,
        96,
        208) 0%,
        rgba(50,
        127,
        182,
        1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6',
        endColorstr='#2c3e50',
        GradientType=1);
        @media (max-width: 768px) {
            margin: 15px auto 50px auto;
        }
    }
    .customerservice {
        position: absolute;
        top: -100px;
        left: 137px;
        width: 224px;
        @media (max-width: 992px) {
            position: absolute;
            top: -85px;
            left: -30px;
            width: 224px;
        }
        @media (max-width: 768px) {
            position: static;
            width: 224px;
            margin: auto;
            display: block;
        }
        svg {
            width: 100%;
            .customerService-color-0 {
                fill: url(#SVGID_1_);
            }
            .customerService-color-1 {
                fill: url(#SVGID_2_);
            }
            .customerService-color-2 {
                fill: url(#SVGID_3_);
            }
            .customerService-color-3 {
                fill: url(#SVGID_4_);
            }
            #eyeLeft {
                @include vendor-prefixes(transition,
                all 3s ease-in-out);
                animation: EyeSpin 3s infinite;
                @include keyframes(EyeSpin) {
                    0% {
                        @include vendor-prefixes(transform,
                        translateX(0px));
                    }
                    10% {
                        @include vendor-prefixes(transform,
                        translateX(10px)translateY(5px));
                    }
                    45% {
                        @include vendor-prefixes(transform,
                        translateX(10px)translateY(5px));
                    }
                    50% {
                        @include vendor-prefixes(transform,
                        translateX(0px));
                    }
                    70% {
                        @include vendor-prefixes(transform,
                        translateX(0px));
                    }
                    75% {
                        @include vendor-prefixes(transform,
                        translateX(-16px));
                    }
                    95% {
                        @include vendor-prefixes(transform,
                        translateX(-16px));
                    }
                    100% {
                        @include vendor-prefixes(transform,
                        translateX(0px));
                    }
                }
            }
            #eyeRight {
                @extend #eyeLeft;
            }
            #mouse {
                @include vendor-prefixes(transition,
                all 5s ease-in-out);
                animation: MouseSpin 5s infinite;
                @include keyframes(MouseSpin) {
                    0% {
                        @include vendor-prefixes(transform,
                        translateX(0px));
                    }
                    10% {
                        @include vendor-prefixes(transform,
                        translateX(20px)translateY(10px));
                    }
                    20% {
                        @include vendor-prefixes(transform,
                        translateX(20px)translateY(10px));
                    }
                    50% {
                        @include vendor-prefixes(transform,
                        translateX(0px));
                    }
                    90% {
                        @include vendor-prefixes(transform,
                        translateX(0px));
                    }
                    95% {
                        @include vendor-prefixes(transform,
                        translateX(-16px)translateY(-10px));
                    }
                    99% {
                        @include vendor-prefixes(transform,
                        translateX(-16px)translateY(-10px));
                    }
                    100% {
                        @include vendor-prefixes(transform,
                        translateX(0px));
                    }
                }
            }
        }
    }
}