
/*about Page*/
.solution-details-cover{
  height: 650px;
  background:  url("../img/cover-solutions.svg")  center center no-repeat;
  background-size: cover;
  .titleWizSloganContainer{
    .titleWizSlogan{
      h1{

      }
      p{

      }
    }
  }
  .coverAvatar{
    width: 223px;
    margin-top: 290px;
    img{
      width: 100%;
    }
    @media only screen and (max-width : 544px) {
      margin: 15px auto auto auto;
      display: block;
    }
    @media only screen and (max-width : 320px) {
      margin: 15px auto auto auto;
      display: block;
      width: 100%;
    }
    .Lamp--0{
      fill:#51E2FB;
    }
    .Lamp--1{
      fill:#4C4C4C;
    }
    .Lamp--2{
      fill:#444444;
    }
  }
}
