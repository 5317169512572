
/**GEORGE COLORS**/


/**$white **/

$whiteo: #ffffff;
.bg_whiteo {  background-color: $whiteo;  }
.text_whiteo {  color: $whiteo;  }

/**gray **/
$light_grayo: #f1f5f7;
.bg_light_grayo {  background-color: $light_grayo;  }
.text_light_grayo {  color: $light_grayo;  }

/**black **/
$blacko: #000000;
.bg_blacko {  background-color: $blacko;  }
.text_blacko {  color: $blacko;  }

/*lightpurple*/
$light_purpleo: #8155db;
.bg_light_purpleo {  background-color: $light_purpleo;  }
.text_light_purpleo {  color: $light_purpleo;  }

/*light purple*/
$dark_purpleo: #4f1f73;
.bg_dark_purpleo {  background-color: $dark_purpleo;  }
.text_dark_purpleo {  color: $dark_purpleo;  }

/*light cyan*/
$light_cyano: #28cae6;
.bg_light_cyano {  background-color: $light_cyano;}
.text_light_cyano {  color: $light_cyano;}

/*light cyan*/
$dark_cyano: #1ba9c8;
.bg_dark_cyano {  background-color: $dark_cyano;  }
.text_dark_cyano {  color: $dark_cyano;  }


/**George Gradient**/
.dark_purpleo_gradient {
  background: $light_purpleo;
  background: rgb(128, 85, 218);
  background: -moz-linear-gradient(left, rgb(127, 85, 217) 0%, rgb(79, 31, 115) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(126, 85, 216)), color-stop(100%, rgb(79, 31, 115)));
  background: -webkit-linear-gradient(left, rgb(125, 85, 215) 0%, rgb(79, 31, 115) 100%);
  background: -o-linear-gradient(left, rgb(124, 85, 214) 0%, rgb(79, 31, 115) 100%);
  background: -ms-linear-gradient(left, rgb(126, 85, 216) 0%, rgb(79, 31, 115) 100%);
  background: linear-gradient(to right, rgb(124, 85, 214) 0%, rgb(79, 31, 115) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}

/**George Gradient**/
.dark_purpleo_gradient_x {
  background: $dark_purpleo;
  background: rgb(79, 31, 115);
  background: -moz-linear-gradient(left, rgb(79, 31, 115) 0%, rgb(126, 85, 216) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(79, 31, 115)), color-stop(100%, rgb(127, 85, 217)));
  background: -webkit-linear-gradient(left, rgb(79, 31, 115) 0%, rgb(127, 85, 217) 100%);
  background: -o-linear-gradient(left, rgb(79, 31, 115) 0%, rgb(124, 85, 214) 100%);
  background: -ms-linear-gradient(left, rgb(79, 31, 115) 0%, rgb(124, 85, 214) 100%);
  background: linear-gradient(to right, rgb(79, 31, 115) 0%, rgb(124, 85, 214) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}


/**George Gradient**/
.dark_cyano_gradient {
  background: $light_cyano;
  background: rgb(40, 201, 229);
  background: -moz-linear-gradient(left, rgb(40, 201, 229) 0%, rgb(27, 168, 199) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(40, 201, 229)), color-stop(100%, rgb(27, 168, 199)));
  background: -webkit-linear-gradient(left, rgb(40, 201, 229) 0%, rgb(27, 168, 199) 100%);
  background: -o-linear-gradient(left, rgb(40, 201, 229) 0%, rgb(27, 168, 199) 100%);
  background: -ms-linear-gradient(left, rgb(40, 201, 229) 0%, rgb(27, 168, 199) 100%);
  background: linear-gradient(to right, rgb(40, 201, 229) 0%, rgb(27, 168, 199) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}

/**George Gradient**/
.dark_cyano_gradient_x {
  background: $dark_cyano;
  background: rgb(27, 168, 199);
  background: -moz-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgb(27, 168, 199)), color-stop(100%, rgb(40, 201, 229)));
  background: -webkit-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
  background: -o-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
  background: -ms-linear-gradient(left, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
  background: linear-gradient(to right, rgb(27, 168, 199) 0%, rgb(40, 201, 229) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#327fb6', endColorstr='#2c3e50', GradientType=1 );
}
