/*********************************/
/********Tickets ***********/
/*********************************/


.ticketbody {
  background: url("../img/partners-all-bg.png") center bottom no-repeat;
  background-size: contain;
  .alltxttype {
    padding-top: 50px;
    .block {
      margin: 20px auto 65px auto;
      h2 {
        text-align: left;
        font-size: 23px;
        text-transform: capitalize;
        font-weight: 600;
      }
      p {
        font-size: 14px;
      }
    }

  }
}

.ticket-cover {
  //height: 590px;
  background: #8155db;
  background-image: url("../img/ticket_bg.jpg");
  background-size: cover;
  //background-size: contain;
  overflow: hidden;
  @media (min-width: 1300px) and (max-width: 1400px) {
    //height: 100vh;
  }

  .titleWizSloganContainer {
    margin: auto;

    .titleWizSlogan {
      padding-top: 180px ;
      padding-bottom: 100px ;
      img {
        width: 100%;
        z-index: 3;
        margin-bottom: 25px;
      }

      h1 {
        font-size: 40px;
      }
      p {
        margin-bottom: 15px;
        span.labeled {
          background: #1e84c8;
          padding: 8px 5px;
          width: 35px;
          height: 35px;
          display: inline-block;
          border-radius: 50%;
          font-size: 15px;
        }
        .btn_style_1 {
          padding: 6px 15px;
        }
      }
    }
  }

  .ticketstatusbar{
    background: rgba(0, 0, 0, 0.17);
    padding-top: 25px;
    padding-bottom: 20px;
    color: #FFFFFF;
    a.closeticket{
      color: #5f5f5f;
      background: #FFFFFF;
      border-radius: 100px;
      padding: 7px 10px 5px 10px;
      font-size: 12px;
      &:hover{
        background: #2ac2e6;
        color: #FFFFFF;

      }
    }
  }

}

.filters {
  margin-top: 60px;
  margin-bottom: 16px;
  input[type="search"] {
    min-width: 100%;
    min-height: 40px;
    padding: 7px 15px 5px 15px;
    display: block;
  }
  input, textarea, select {
    background: #FFFFFF;
    @include vendor-prefixes(border-radius, 50px);
    padding: 2px 3px 2px 20px;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
    border: 1px solid transparent;
    text-transform: capitalize;
    outline: none;
    @include vendor-prefixes(transition, all 1s ease-in-out);
    max-width: 100%;
    @media (min-width: 1200px) {
      max-width: 100%;
    }
  }
  input:focus:not([readonly]) {
    box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
    border: 1px solid #d4d8da;
  }
  select {
    min-width: 100%;
    min-height: 40px;
    padding: 7px 15px 5px 15px;
    display: block;
  }
  select:focus {
    outline: none;
    border: 1px solid #d4d8da;

  }

}

#ticket-tables {
  max-height: 400px;
  overflow-y: scroll;
  height: 400px !important;
  margin-bottom: 50px;

  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 1px;
    background-color: #FFFFFF;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #cdcdcd;
  }

  .table-bordered {
    background: #FFFFFF;
    border-radius: 20px;
    thead tr {
      background: #beced6;
      border-radius: 20px 20px 0 0;
      th {
        padding: 15px 10px;
        color: #ffffff;
        font-weight: normal;

        &:first-child {
          border-top-left-radius: 25px;
          border-left-color: transparent;
          border-top-color: transparent;
        }
        &:last-child {
          border-top-right-radius: 25px;
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }

    }
    tbody {
      //max-height: 500px;
      //overflow-y: scroll;
      tr {
        td {
          padding: 10px 10px;

        }
      }
    }
  }

}

@media only screen and (max-width: 800px) {

  /* Force table to not be like tables anymore */
  #ticket-tables table,
  #ticket-tables thead,
  #ticket-tables tbody,
  #ticket-tables th,
  #ticket-tables td,
  #ticket-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #ticket-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #ticket-tables tr {
    border: 1px solid rgba(129, 85, 219, 0.16)
  }

  #ticket-tables td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  #ticket-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  /*
  Label the data
  */
  #ticket-tables td:before {
    content: attr(data-title);
  }

  #ticket-tables .table-bordered {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

#firstName{
  display: none;
}
#lastName{
  display: none;
}
.statuslabel{
  text-transform: capitalize;
  background: #FFFFFF;
  padding: 6px 12px 4px 12px;
  line-height: 26px;
  font-size: 12px;
  border-radius: 200px;
  color: #fff;
}
.Onhold{
  background: #a3bac6;
}
.answered{
  background: #2b5082;

}
.pending{
  background: #2cb9d0;
}
.closed{
  background: #2d2d2d;
}


/* write ticket */
.issuecontainer{
  height: 240px;
  position: relative;
  margin:40px auto 45px auto;
  @include userSelect(none);

  label.issuefield {
    background: #dedbe2;
    display: block;
    margin: 0;
    padding: 15px 8px 15px 25px;
    font-size: 15px;
    color: #5f5f5f;
    border-radius: 20px 20px 0 0;
    max-height: 50px;
    overflow: hidden;
    span{
      font-weight: bold;
      text-overflow: ellipsis;
    }
  }
  textarea#issuefield{
    height: 135px;
    resize: none;
    background:#f9f9f9;
    border: 0;
    padding: 20px;
    font-size: 15px;
    margin: 0;
    &:focus{
      background: #fff;
      border: 0;
      padding: 20px;
      font-size: 15px;
      outline: 0;
    }

    &::-webkit-scrollbar-track {
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      @include vendor-prefixes(border-radius, 1px);

      background-color: #FFFFFF;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      @include vendor-prefixes(border-radius, 10px);

      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #cdcdcd;
    }
  }
  .whitearea{
    display: block;
    background: #fff;
    height: 55px;
    margin: -9px 0 0 0;
    @include vendor-prefixes(border-radius, 0 0 20px 20px);

  }

  #textarea_characters{
    position: absolute;
    bottom: 15px;
    left: 40px;
    z-index: 2;
    @include userSelect(none);

  }
  .attachfile {
    color: transparent;
    position: absolute;
    right: 45px;
    bottom: 15px;
    outline: 0;
    width: 125px;
    margin: 0;
    height: 30px;
    &::-webkit-file-upload-button {
      visibility: hidden;
    }
    &::before {
      content: 'Attach';
      cursor: pointer;
      position: absolute;
      color: #f9f9f9;
      background: #2ac2e6;
      @include vendor-prefixes(border-radius, 100px);
      padding: 7px 10px 5px 10px;
      font-size: 12px;
      border: 0;
      outline: none;
    }
    &:hover::before {
      border-color: black;
    }
    &:active {
      outline: 0;
    }
    &:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }
    @include userSelect(none);

  }
  .submitreply{
    position: absolute;
    right: 20px;
    bottom: 15px;
    color: #f9f9f9;
    background: #2ac2e6;
    @include vendor-prefixes(border-radius, 100px);
    @include userSelect(none);
    padding: 7px 10px 5px 10px;
    font-size: 12px;
    border: 0;
    outline: none;
  }
  .hint{
    text-align: right;
    font-size: 11px;
    color: #a7a7a7;
    margin-top: 4px;
    margin-right: 20px;
    @include userSelect(none);
  }
}

/**/

hr.separatorreplies{
  border-top: 1px solid rgb(79, 31, 115);
}

h6.separatortitle{
  background: #f1f5f7;
  display: table;
  margin: -25px auto 10px auto;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: $dark_purpleo;
  padding: 0 10px;
}

/* replies*/
.replycontainer{
  height: auto;
  margin:10px auto 10px auto;
  @include userSelect(none);

  label.replylabel{
    background: #dedbe2;
    display: block;
    margin: 0;
    padding: 15px 8px 15px 25px;
    font-size: 15px;
    color: #5f5f5f;
    @include vendor-prefixes(border-radius, 20px 20px 0 0);

    max-height: 50px;
    overflow: hidden;
    span{
      font-weight: bold;
      text-overflow: ellipsis;
    }
    small{
      text-align: right;
      float: right;
      margin-right: 10px;
    }
  }

  .replaytxt{
    @include vendor-prefixes(border-radius, 0 0 20px 20px);
    background: #ffffff;
    min-height: 75px;
    display: block;
    padding: 15px;
  }
}

.ticketsbtons{
  margin: 18px auto 30px auto;
  @include userSelect(none);
}
.openticket , .allticket {
  border: 2px solid $dark_purpleo;
  padding: 8px 15px;
  color: $dark_purpleo;
  @include vendor-prefixes(border-radius, 500px);
  @include vendor-prefixes(transition, all 0.6s ease-in-out);
  @include vendor-prefixes(user-select, none);
  font-size: 18px;
  text-decoration: none;
  margin: 5px;
  min-width: 162px;
  display: inline-block;
  &:hover{
    color: $dark_purpleo;
    background: $whiteo;
  }
}






//////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////// Modals New Tickets
//////////////////////////////////////////////////////////////////////////////////////////
.requestquota, .subscribe , .newticket , .supportlogin {
  background: #ffffff;
  overflow-x: hidden;
  .closestyle{
    background: $light_purpleo;
    text-align: center;
    margin-bottom: 50px;
    padding: 10px;
    color: $whiteo;
    cursor: pointer;
    @include vendor-prefixes(transition, all 1s ease-in-out);
    &:hover{
      background: $dark_purpleo;
    }
  }
  &__title{
    font-weight: bold;
    font-size: 35px;
    @include userSelect(none);
    margin-bottom: 0;
  }
  &__slogan{
    margin-top: -1px;
    @include userSelect(none);
    letter-spacing: 8px;
    text-indent: 8px;

  }
  .modal-content{
    padding-right: 20px;
    border: 0;
    input, textarea, select {
      background: #f1f5f7;
      @include vendor-prefixes(border-radius, 50px);
      padding: 2px 3px 2px 20px;
      font-size: 14px;
      margin-bottom: 5px;
      margin-top: 5px;
      border: 1px solid transparent;
      @include vendor-prefixes(transition, all 1s ease-in-out);
      max-width: 100%;
      @media (min-width: 1200px) {
        max-width: 100%;
      }
    }
    input:focus:not([readonly]){
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
      border: 1px solid #d4d8da;
    }
    select{
      min-width: 100%;
      min-height: 40px;
      padding: 7px 15px 5px 15px;
      display: block;
    }
    select:focus{
      outline: none;
      border: 1px solid #d4d8da;

    }
    textarea{
      @include vendor-prefixes(border-radius, 15px);
      min-height: 100px;
      max-height: 220px;
    }
    textarea:focus:not([readonly]){
      box-shadow: 0 0 0 0 rgba(66, 133, 244, 0);
      border: 1px solid #d4d8da;
      outline: 0;
    }

    a{
      font-size: 14px;
      text-align: left;
    }
    button{
      display: block;
      background: $light_purpleo;
      width: 100%;
      max-width: 375px;
      margin: 14px auto;
      border-radius: 100px;
      min-height: 42px;
      outline: 0;
      border:2px solid $whiteo;
      padding: 2px;
      color: $whiteo;
      @include vendor-prefixes(border-radius, 200px);
      @include vendor-prefixes(transition, all 0.6s ease-in-out);
      font-size: 15px;
      text-decoration:none;
      position: relative;

      i{
        font-size: 14px;
        @include vendor-prefixes(transition, all 0.6s ease-in-out);
      }
      &:hover {
        @include vendor-prefixes(transition, all 0.6s ease-in-out);
        background: $dark_purpleo;
        color: $whiteo;
        i{
          margin-left: 8px;
        }
      }
    }
    img{
      width: 100%;
    }
  }
}


.subscribe{
  .closestyle{
    background: $light_cyano;
    &:hover{
      background: $dark_cyano;
    }
  }
  .modal-content{
    button{
      background: $light_cyano;
      &:hover {
        background: $dark_cyano;

      }
    }
  }
}

.newticket{
  .closestyle{
    background: $light_cyano;
    &:hover{
      background: $dark_cyano;
    }
  }
  .modal-content{
    button{
      background: $light_cyano;
      &:hover {
        background: $dark_cyano;

      }
    }
  }
}

.supportlogin{
  .closestyle{
    background: $light_cyano;
    &:hover{
      background: $dark_cyano;
    }
  }
  .modal-content{
    button{
      background: $light_cyano;
      &:hover {
        background: $dark_cyano;

      }
    }
  }
}